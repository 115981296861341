import { memo } from 'react';

function SpendIcon({ color }: any) {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='48'
        height='48'
        viewBox='0 0 48 48'
        fill='none'
      >
        <path
          d='M21.9998 4.09863L22 10.1416C15.2154 11.1121 10 16.9469 10 23.9998C10 31.7318 16.268 37.9998 24 37.9998C27.1446 37.9998 30.0472 36.963 32.3844 35.2126L36.6578 39.4854C33.21 42.307 28.8028 43.9998 24 43.9998C12.9543 43.9998 4 35.0456 4 23.9998C4 13.6292 11.8932 5.10217 21.9998 4.09863ZM43.9012 26C43.5018 30.022 41.911 33.6934 39.4866 36.6564L35.2128 32.3842C36.5852 30.5518 37.519 28.3718 37.8582 25.9998L43.9012 26ZM26.0022 4.09883C35.45 5.03789 42.963 12.5517 43.9012 21.9996L37.8582 21.9994C36.981 15.8689 32.1322 11.0197 26.002 10.1419L26.0022 4.09883Z'
          fill={color}
        />
        <path
          d='M32 23.4444V31.2222C32 31.6518 31.6418 32 31.2 32H16.8C16.3582 32 16 31.6518 16 31.2222V23.4444H32ZM32 21.8889H16V18.7778C16 18.3482 16.3582 18 16.8 18H31.2C31.6418 18 32 18.3482 32 18.7778V21.8889ZM26.4 28.1111V29.6667H29.6V28.1111H26.4Z'
          fill={color}
        />
      </svg>
    </>
  );
}

export default memo(SpendIcon);
