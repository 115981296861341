import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// components
import { SplashScreen } from '@atoms/loading-screen';
import { DashboardLayout } from '@atoms/layouts';
// routes
import { RequiredPerm } from '@routes/permissions';
import { paths } from '@routes/paths';
// hocs
import AuthGuard from '@hocs/auth-guard';
import { CheckPermission } from '@hocs/check-permission';

// ----------------------------------------------------------------------

// ROUTE SCHEME
//  |-Campaigns
//  |--Summary tab (*initial)
//  |--Ads tab
//  |--|--Root page
//  |--|--Ad's details page
//  |--|--Create campaign page
//  |--|--Edit campaign page
//  |--|--Area perfomance page
//  |--Discount tab
//  |--|--Root page
//  |--|--Create discount page
//  |--|--RBDC discount detail page
//  |--Calendar tab

// ----------------------------------------------------------------------

// OVERVIEW OF NESTING PAGES
// Summary tab
const MarketingOverviewPage = lazy(() => import('@pages/campaigns/Summary'));
// Ads tab
const AdsAppPage = lazy(() => import('@pages/campaigns/Ads'));
const AdsDetailAppPage = lazy(() => import('@pages/campaigns/Ads/ads-detail'));
const CreateCampaignPage = lazy(
  () => import('@pages/campaigns/Ads/create-campaign')
);
const EditCampaignPage = lazy(
  () => import('@pages/campaigns/Ads/edit-campaign')
);
const AreaPerformanceView = lazy(
  () => import('@pages/campaigns/Ads/ads-area-performance')
);
// Discount tab
const DiscountPage = lazy(() => import('@pages/campaigns/Discount'));
const CreateDiscountCampaignPage = lazy(
  () => import('@pages/campaigns/Discount/create-discount-campaign')
);
const RBDCDiscountDetailPage = lazy(
  () => import('@pages/campaigns/Discount/rbdc-discount-details')
);
// Calendar tab
const CalendarPage = lazy(() => import('@pages/campaigns/Calendar'));

// ----------------------------------------------------------------------

export const campaignRoutes = [
  {
    path: paths?.campaigns?.root,
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<SplashScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        element: (
          <CheckPermission permissionRequired={RequiredPerm?.Marketing}>
            <MarketingOverviewPage />
          </CheckPermission>
        ),
        index: true
      },
      {
        path: paths?.campaigns?.ads,
        element: (
          <CheckPermission permissionRequired={RequiredPerm?.Ads}>
            <AdsAppPage />
          </CheckPermission>
        )
      },
      {
        path: 'ads/:id',
        element: (
          <CheckPermission permissionRequired={RequiredPerm?.Ads}>
            <AdsDetailAppPage />
          </CheckPermission>
        )
      },
      {
        path: paths?.campaigns?.area_performance,
        element: (
          <CheckPermission permissionRequired={RequiredPerm?.Ads}>
            <AreaPerformanceView />
          </CheckPermission>
        )
      },
      {
        path: 'ads/campaign/edit/:id',
        element: (
          <CheckPermission permissionRequired={RequiredPerm?.Ads}>
            <EditCampaignPage />
          </CheckPermission>
        )
      },
      {
        path: paths?.campaigns?.campaign_add,
        element: (
          <CheckPermission permissionRequired={RequiredPerm?.Ads}>
            <CreateCampaignPage />
          </CheckPermission>
        )
      },
      {
        path: paths?.campaigns?.discount,
        element: (
          <CheckPermission permissionRequired={RequiredPerm?.Discount}>
            <DiscountPage />
          </CheckPermission>
        )
      },
      {
        path: 'discount/rbdc/:id',
        element: (
          <CheckPermission permissionRequired={RequiredPerm?.Discount}>
            <RBDCDiscountDetailPage />
          </CheckPermission>
        )
      },
      {
        path: paths?.campaigns?.calendar,
        element: (
          <CheckPermission permissionRequired={RequiredPerm?.Calendar}>
            <CalendarPage />
          </CheckPermission>
        )
      },
      {
        path: paths?.campaigns?.discount_campaign_add,
        element: (
          <CheckPermission permissionRequired={RequiredPerm?.Discount}>
            <CreateDiscountCampaignPage />
          </CheckPermission>
        )
      }
    ]
  }
];
