import { memo } from 'react';

function OrderConversionIcon({ color }: any) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
    >
      <path
        d='M2.87996 12.2875H3.83996L17.856 30.9115V43.1995C17.856 43.7755 18.24 44.3515 18.624 44.7355C19.008 44.9275 19.392 45.1195 19.776 45.1195C19.968 45.1195 20.352 45.1195 20.544 44.9275L28.992 41.6635C29.76 41.4715 30.144 40.7035 30.144 39.9355V39.3595L29.952 39.1675L28.992 38.3995C27.84 37.2475 27.456 35.9035 27.456 34.3675C26.304 33.4075 25.728 32.0635 25.728 30.5275V29.3755C25.728 27.8395 26.496 26.4955 27.456 25.5355C27.264 23.9995 27.84 22.6555 28.992 21.5035L29.76 20.7355C30.72 19.7755 32.064 19.1995 33.216 19.1995H33.6C34.56 18.0475 35.904 17.4715 37.44 17.4715H38.592C38.976 17.4715 39.36 17.4715 39.744 17.6635L44.16 12.2875H45.12C46.272 12.2875 47.04 11.5195 47.04 10.3675V4.6075C47.04 3.4555 46.272 2.6875 45.12 2.6875H2.87996C1.91996 2.6875 0.959961 3.4555 0.959961 4.6075V10.3675C0.959961 11.5195 1.72796 12.2875 2.87996 12.2875Z'
        fill={color}
      />
      <path
        d='M45.8879 28.2251C45.3119 28.2251 44.7359 27.8411 44.5439 27.4571C44.3519 27.0731 44.3519 26.4971 44.7359 26.1131C45.1199 25.7291 45.1199 24.9611 44.7359 24.3851L44.1599 23.4251C43.7759 23.0411 43.0079 23.0411 42.4319 23.4251C42.0479 23.8091 41.6639 23.8091 41.0879 23.6171C40.7039 23.4251 40.3199 23.0411 40.3199 22.4651V22.2731C40.3199 21.5051 39.7439 21.1211 39.1679 21.1211H38.0159C37.4399 21.1211 36.8639 21.6971 36.8639 22.2731V22.4651C36.8639 22.8491 36.4799 23.4251 36.0959 23.6171C35.7119 23.8091 35.1359 23.6171 34.7519 23.4251C34.3679 23.0411 33.5999 23.0411 33.0239 23.4251L32.2559 24.1931C31.8719 24.5771 31.8719 25.3451 32.2559 25.9211C32.6399 26.3051 32.6399 26.8811 32.4479 27.2651C32.2559 27.6491 31.8719 28.0331 31.2959 28.0331H31.1039C30.5279 28.0331 29.9519 28.6091 29.9519 29.1851V30.3371C29.9519 31.1051 30.5279 31.4891 31.1039 31.4891H31.2959C31.8719 31.4891 32.2559 31.8731 32.4479 32.2571C32.6399 32.6411 32.6399 33.2171 32.2559 33.6011C31.8719 33.9851 31.8719 34.7531 32.2559 35.3291L33.0239 36.0971C33.4079 36.4811 34.1759 36.4811 34.7519 36.0971C35.1359 35.7131 35.7119 35.7131 36.0959 35.9051C36.4799 36.0971 36.8639 36.4811 36.8639 37.0571V37.2491C36.8639 37.8251 37.4399 38.4011 38.0159 38.4011H39.1679C39.7439 38.4011 40.3199 37.8251 40.3199 37.2491V37.0571C40.3199 36.4811 40.7039 36.0971 41.0879 35.9051C41.4719 35.7131 42.0479 35.9051 42.4319 36.0971C42.8159 36.4811 43.5839 36.4811 44.1599 36.0971L44.9279 35.3291C45.3119 34.9451 45.3119 34.1771 44.9279 33.6011C44.5439 33.2171 44.5439 32.6411 44.7359 32.2571C44.9279 31.8731 45.3119 31.4891 45.8879 31.4891H46.0799C46.6559 31.4891 47.2319 30.9131 47.2319 30.3371V29.1851C47.0399 28.8011 46.4639 28.2251 45.8879 28.2251ZM38.3999 32.8331C36.8639 32.8331 35.5199 31.4891 35.5199 29.9531C35.5199 28.4171 36.8639 27.0731 38.3999 27.0731C39.9359 27.0731 41.2799 28.4171 41.2799 29.9531C41.2799 31.6811 39.9359 32.8331 38.3999 32.8331Z'
        fill={color}
      />
    </svg>
  );
}

export default memo(OrderConversionIcon);
