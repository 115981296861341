import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
// @mui
import Box from '@mui/material/Box';
// hooks
import { useBoolean } from '@hooks/use-boolean';
// context
import { useSettingsContext } from '@context/settings';
// API
import { ManagementService } from '@modules/management/api';
// components
import AlertBox from '@atoms/alert/alert';
import Header from '@atoms/header/header';
import NavVertical from '@atoms/nav-section/nav-vertical';
import Main from './main';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function DashboardLayout({ children }: Props) {
  const [subscriptionStatus, setSubscriptionStatus] = useState<string | null>(
    null
  );

  const settings = useSettingsContext();
  const nav = useBoolean();

  const isHorizontal = settings.themeLayout === 'horizontal';
  const isMini = settings.themeLayout === 'mini';

  const renderNavVertical = (
    <NavVertical isMini={isMini} openNav={nav.value} onCloseNav={nav.onFalse} />
  );

  const fetchSubscriptionStatus = async () => {
    try {
      const subResponse = await ManagementService.fetchSubscriptionStatus();
      setSubscriptionStatus(subResponse.status);
    } catch (error) {
      enqueueSnackbar(`Error fetching subscription status: ${error}`, {
        variant: 'error'
      });
    }
  };
  useEffect(() => {
    fetchSubscriptionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isHorizontal) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        {renderNavVertical}

        <Main>
          <>
            <AlertBox
              isVisible={subscriptionStatus === 'unpaid'}
              type='warning'
              cause='stripe'
            />
            {children}
          </>
        </Main>
      </>
    );
  }

  // if (isMini) {
  //   return (
  //     <>
  //       <Header onOpenNav={nav.onTrue} />

  //       <Box
  //         sx={{
  //           minHeight: 1,
  //           display: 'flex',
  //           flexDirection: { xs: 'column', md: 'row' },
  //         }}
  //       >
  //         {lgUp ? renderNavMini : renderNavVertical}

  //         <Main>
  //           <>
  //             <AlertBox isVisible={subscriptionStatus === 'unpaid'} type='warning' cause='stripe'/>
  //             {children}
  //           </>
  //         </Main>
  //       </Box>
  //     </>
  //   );
  // }

  return (
    <>
      <Header onOpenNav={nav.onTrue} />

      <Box
        sx={{
          minHeight: 1
        }}
      >
        {renderNavVertical}

        <Main>
          <>
            <AlertBox
              isVisible={subscriptionStatus === 'unpaid'}
              type='warning'
              cause='stripe'
            />
            {children}
          </>
        </Main>
      </Box>
    </>
  );
}
