import { enqueueSnackbar } from 'notistack';
// routes
import { paths } from '@routes/paths';
// utils
import axios from '@utils/axios';

// ----------------------------------------------------------------------

export function jwtDecode(token: string) {
  const base64Url = token.split('.')[1];
  if (!base64Url) {
    enqueueSnackbar('Invalid token', { variant: 'error' });
  }
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export const isValidToken = (accessToken: string | null) => {
  if (!accessToken) {
    const accessTokenFromCookie = getCookie('accessToken');
    if (!accessTokenFromCookie) {
      return false;
    }
    const accessTokenData = accessTokenFromCookie;
    setSession(accessTokenData);
  }

  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------

export const tokenExpired = (exp: number) => {
  let expiredTimer;
  const currentTime = Date.now();
  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    enqueueSnackbar('Token expired');

    sessionStorage.removeItem('accessToken');
    removeCookie();
    window.location.href = paths.auth.jwt.login;
  }, timeLeft);
};

// ----------------------------------------------------------------------

export const setSession = (accessToken: string | null) => {
  if (accessToken) {
    sessionStorage.setItem('accessToken', accessToken);
    // Set access token in cookies
    document.cookie = `accessToken=${accessToken}; expires=Thu, 01 Jan 2099 00:00:00 UTC; path=/`;
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    const { exp } = jwtDecode(accessToken);
    tokenExpired(exp);
  } else {
    removeCookie();
    sessionStorage.removeItem('accessToken');

    delete axios.defaults.headers.common.Authorization;
  }
};

//-------------------------------------------------------------------------------------
export const getCookie = (name: string): string | null => {
  const cookieString = document.cookie;
  const cookiePairs = cookieString
    .split(';')
    .map(pair => pair.trim().split('='));

  const cookie = cookiePairs.find(([cookieName]) => cookieName === name);

  return cookie ? cookie[1] : null;
};

// Clear cookies
export const clearCookies = () => {
  const cookies = document.cookie.split(';');

  cookies.forEach(cookie => {
    const [name] = cookie.split('=');
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  });
};

// Clear cache
const clearCache = () => {
  caches
    .keys()
    .then(names => Promise.all(names.map(name => caches.delete(name))));
};

// Clear cookies and cache
export const removeCookie = () => {
  clearCookies();
  clearCache();
};

export const getSession = () => sessionStorage.getItem('accessToken') || null;

export const getClientCountry = () => {
  const accessToken = jwtDecode(getSession() || '');
  return accessToken?.country_name || null;
};
