import { memo } from 'react';

function AddNewCustomerIcon() {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='49'
        height='48'
        viewBox='0 0 49 48'
        fill='none'
      >
        <path
          d='M24.667 20C29.0853 20 32.667 16.4183 32.667 12C32.667 7.58172 29.0853 4 24.667 4C20.2487 4 16.667 7.58172 16.667 12C16.667 16.4183 20.2487 20 24.667 20Z'
          fill='#02D2C5'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M33.667 44C30.367 44 28.717 44 27.693 42.974C26.667 41.95 26.667 40.3 26.667 37C26.667 33.7 26.667 32.05 27.693 31.026C28.717 30 30.367 30 33.667 30C36.967 30 38.617 30 39.641 31.026C40.667 32.05 40.667 33.7 40.667 37C40.667 40.3 40.667 41.95 39.641 42.974C38.617 44 36.967 44 33.667 44ZM34.833 33.888C34.833 33.5788 34.7101 33.2822 34.4915 33.0635C34.2728 32.8448 33.9762 32.722 33.667 32.722C33.3577 32.722 33.0612 32.8448 32.8425 33.0635C32.6238 33.2822 32.501 33.5788 32.501 33.888V35.834H30.555C30.2458 35.834 29.9492 35.9568 29.7305 36.1755C29.5118 36.3942 29.389 36.6908 29.389 37C29.389 37.3092 29.5118 37.6058 29.7305 37.8245C29.9492 38.0432 30.2458 38.166 30.555 38.166H32.501V40.112C32.501 40.4212 32.6238 40.7178 32.8425 40.9365C33.0612 41.1552 33.3577 41.278 33.667 41.278C33.9762 41.278 34.2728 41.1552 34.4915 40.9365C34.7101 40.7178 34.833 40.4212 34.833 40.112V38.166H36.779C37.0882 38.166 37.3848 38.0432 37.6035 37.8245C37.8221 37.6058 37.945 37.3092 37.945 37C37.945 36.6908 37.8221 36.3942 37.6035 36.1755C37.3848 35.9568 37.0882 35.834 36.779 35.834H34.833V33.888Z'
          fill='#02D2C5'
        />
        <path
          d='M32.023 27.006C31.077 27.016 30.195 27.052 29.427 27.154C28.141 27.328 26.733 27.74 25.571 28.904C24.407 30.066 23.995 31.474 23.823 32.76C23.667 33.916 23.667 35.328 23.667 36.828V37.172C23.667 38.672 23.667 40.084 23.823 41.24C23.943 42.142 24.183 43.104 24.717 44H24.667C8.66699 44 8.66699 39.97 8.66699 35C8.66699 30.03 15.831 26 24.667 26C27.319 26 29.821 26.362 32.023 27.006Z'
          fill='#02D2C5'
        />
      </svg>
    </>
  );
}

export default memo(AddNewCustomerIcon);
