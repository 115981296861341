import { memo } from 'react';

function TotalReturningCustomers() {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24.0002 22.1523C26.5002 22.1523 28.5268 24.1602 28.5268 26.637C28.5268 29.1138 26.5002 31.1217 24.0002 31.1217C21.5003 31.1217 19.4737 29.1138 19.4737 26.637C19.4737 24.1601 21.5003 22.1523 24.0002 22.1523ZM32.9013 39.8361C32.4206 35.3863 28.619 31.9207 24.0002 31.9207C19.3813 31.9207 15.5798 35.3863 15.0991 39.8362V41.629C15.0991 42.9345 16.1739 43.9993 17.4916 43.9993H30.5087C31.8265 43.9993 32.9013 42.9345 32.9013 41.629V39.8361ZM37.5284 25.4091C39.3986 25.4091 40.9148 26.9113 40.9148 28.7641C40.9148 30.6171 39.3986 32.1192 37.5284 32.1192C35.6581 32.1192 34.142 30.6171 34.142 28.7641C34.1419 26.9113 35.658 25.4091 37.5284 25.4091ZM10.4721 25.4091C12.3423 25.4091 13.8585 26.9113 13.8585 28.7641C13.8585 30.6171 12.3423 32.1192 10.4721 32.1192C8.60182 32.1192 7.08568 30.6171 7.08568 28.7641C7.08568 26.9113 8.60182 25.4091 10.4721 25.4091ZM10.472 33.0154C12.4456 33.0154 14.2197 33.8611 15.4455 35.2066C14.5804 36.5043 14.0068 38.0145 13.8219 39.6514H5.53527C5.03309 39.6514 4.60099 39.4351 4.30301 39.0346C4.00502 38.6342 3.92429 38.1612 4.07284 37.6859C4.91841 34.9806 7.46367 33.0154 10.472 33.0154ZM37.5283 33.0154C40.5368 33.0154 43.082 34.9806 43.9275 37.6859C44.076 38.1612 43.9953 38.6341 43.6973 39.0346C43.3993 39.4351 42.9673 39.6514 42.465 39.6514H34.1784C33.9935 38.0145 33.4198 36.5044 32.5548 35.2066C33.7807 33.8611 35.5548 33.0154 37.5283 33.0154Z'
        fill='#FFCC47'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24.3375 4.20635V2.62935C24.3375 2.09458 24.9632 1.8044 25.3715 2.14984L29.2148 5.40188C29.5113 5.65279 29.5113 6.11 29.2148 6.36091L25.3715 9.61299C24.9632 9.95839 24.3375 9.66818 24.3375 9.13342V7.55645C21.2317 7.55645 19.5655 7.55645 16.6696 9.89222C16.3895 10.1181 15.9699 9.92061 16.0017 9.56232C16.4778 4.20635 21.1608 4.20635 24.3375 4.20635ZM22.6625 16.7692V18.3462C22.6625 18.881 22.0368 19.1712 21.6286 18.8258L17.7853 15.5737C17.4887 15.3228 17.4887 14.8655 17.7853 14.6147L21.6286 11.3626C22.0368 11.0172 22.6625 11.3074 22.6625 11.8421V13.4191C25.7684 13.4191 27.4345 13.4191 30.3304 11.0833C30.6104 10.8575 31.0302 11.055 30.9983 11.4133C30.5221 16.7692 25.8392 16.7692 22.6625 16.7692Z'
        fill='#FFCC47'
      />
    </svg>
  );
}

export default memo(TotalReturningCustomers);
