import { memo } from 'react';

function ItemsDiscountIcon() {
  // const color = 'currentColor';

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='62'
      height='62'
      viewBox='0 0 62 62'
      fill='none'
    >
      <g clipPath='url(#clip0_1459_31226)'>
        <path
          d='M1.75981 24.4678C0.785003 24.4678 0.0003158 25.267 0.0148471 26.243C0.117777 32.7191 0.71598 37.1887 1.34325 40.7246C2.5445 47.0699 8.4127 52.9393 14.7592 54.1406C18.6161 54.8248 23.8582 54.8938 31.0149 54.9035C38.1715 54.8938 43.4136 54.8248 47.2705 54.1406C53.6158 52.9393 59.4852 47.0711 60.6865 40.7246C61.3137 37.1887 61.9131 32.7203 62.0149 26.243C62.0306 25.2682 61.2447 24.469 60.2699 24.469H1.75981V24.4678Z'
          fill='#FD5C6F'
        />
        <path
          d='M49.2471 57.4395H12.7821C11.5228 57.4395 10.502 58.4603 10.502 59.7197C10.502 60.979 11.5228 61.9998 12.7821 61.9998H49.2471C50.5064 61.9998 51.5273 60.979 51.5273 59.7197C51.5273 58.4603 50.5064 57.4395 49.2471 57.4395Z'
          fill='#454565'
        />
        <path
          d='M30.976 18.1256C30.534 18.1256 30.0932 17.9573 29.7554 17.6207C29.0809 16.9462 29.0809 15.8539 29.7554 15.1806C30.4093 14.5255 30.7689 13.6561 30.7689 12.7321C30.7689 11.8082 30.4299 10.9908 29.8135 10.3441L29.7542 10.2848C28.4488 8.97942 27.7295 7.24293 27.7295 5.39625C27.7295 3.54957 28.4439 1.82399 29.7421 0.519809C30.373 -0.12441 31.4035 -0.177691 32.0986 0.416879C32.8227 1.03688 32.9062 2.12672 32.2862 2.84965L32.1954 2.94774C31.5415 3.60164 31.1819 4.4711 31.1819 5.39625C31.1819 6.32141 31.5209 7.13758 32.1373 7.78422L32.1966 7.84356C34.8922 10.5391 34.8922 14.9251 32.1966 17.6207C31.86 17.9573 31.418 18.1268 30.976 18.1268V18.1256Z'
          fill='#FF8A98'
        />
        <path
          d='M17.5073 21.8336C17.0653 21.8336 16.6245 21.6653 16.2866 21.3287C15.6121 20.6542 15.6121 19.5619 16.2866 18.8886C16.9405 18.2335 17.3002 17.3641 17.3002 16.4401C17.3002 15.5162 16.9611 14.6988 16.3448 14.0522L16.2854 13.9928C14.98 12.6874 14.2607 10.9509 14.2607 9.10426C14.2607 7.25758 14.9752 5.532 16.2733 4.22782C16.9042 3.5836 17.9347 3.53032 18.6298 4.12489C19.3539 4.74489 19.4375 5.83473 18.8175 6.55766L18.7267 6.65575C18.0728 7.30965 17.7131 8.17911 17.7131 9.10426C17.7131 10.0294 18.0522 10.8456 18.6686 11.4922L18.7279 11.5516C21.4234 14.2471 21.4234 18.6331 18.7279 21.3287C18.3912 21.6653 17.9493 21.8348 17.5073 21.8348V21.8336Z'
          fill='#FF8A98'
        />
        <path
          d='M43.5961 21.8336C43.1541 21.8336 42.7134 21.6653 42.3755 21.3287C41.701 20.6542 41.701 19.5619 42.3755 18.8886C43.0294 18.2335 43.3891 17.3641 43.3891 16.4401C43.3891 15.5162 43.05 14.6988 42.4336 14.0522L42.3743 13.9928C41.0689 12.6874 40.3496 10.9509 40.3496 9.10426C40.3496 7.25758 41.0641 5.532 42.3622 4.22782C42.9931 3.5836 44.0236 3.53032 44.7187 4.12489C45.4428 4.74489 45.5264 5.83473 44.9064 6.55766L44.8155 6.65575C44.1616 7.30965 43.802 8.17911 43.802 9.10426C43.802 10.0294 44.1411 10.8456 44.7574 11.4922L44.8168 11.5516C47.5123 14.2471 47.5123 18.6331 44.8168 21.3287C44.4801 21.6653 44.0381 21.8348 43.5961 21.8348V21.8336Z'
          fill='#FF8A98'
        />
        <path
          d='M46.9714 24.4678H1.75981C0.785003 24.4678 0.000315801 25.267 0.0148471 26.243C0.116566 32.7191 0.71598 37.1887 1.34325 40.7246C1.75496 42.9019 2.71766 45.0234 4.05938 46.9198C7.42457 48.0568 11.0295 48.6744 14.7786 48.6744C30.0546 48.6744 42.9414 38.4493 46.9714 24.4702V24.4678Z'
          fill='#FF8A98'
        />
        <path
          d='M50.5942 39.1592C44.2864 39.1592 39.1738 44.2718 39.1738 50.5795C39.1738 56.8873 44.2864 61.9999 50.5942 61.9999C56.902 61.9999 62.0145 56.8873 62.0145 50.5795C62.0145 44.2718 56.902 39.1592 50.5942 39.1592Z'
          fill='#02D2C5'
        />
        <path
          d='M49.2903 47.0015C49.3037 46.0582 49.0033 45.2553 48.3906 44.6002C47.7827 43.9451 47.0525 43.6351 46.1867 43.6617C45.3257 43.6884 44.5628 44.048 43.8956 44.7165C43.2296 45.3861 42.8736 46.1781 42.8421 47.0996C42.8106 48.032 43.1085 48.8143 43.7297 49.4537C44.3509 50.093 45.1126 50.4151 46.0051 50.42C46.9133 50.42 47.6822 50.0954 48.3167 49.4428C48.9525 48.7901 49.2758 47.9763 49.2891 47.0015H49.2903ZM46.8709 48.0005C46.6493 48.256 46.372 48.3844 46.0402 48.3868C45.6939 48.3904 45.4153 48.2669 45.2022 48.0284C44.9891 47.785 44.8874 47.4653 44.8983 47.0596C44.9092 46.6782 45.0327 46.3658 45.2628 46.1199C45.4928 45.8693 45.775 45.7421 46.1044 45.7349C46.4337 45.7276 46.7026 45.8438 46.9121 46.0885C47.1216 46.3331 47.2233 46.6455 47.2148 47.0257C47.2063 47.4205 47.0925 47.7462 46.8709 48.0017V48.0005Z'
          fill='white'
        />
        <path
          d='M54.0842 43.7862C54.0842 43.7862 53.538 43.7669 53.2571 44.25C50.9369 47.8707 48.5005 51.5072 46.2881 55.0177C45.9866 55.496 46.121 56.1632 46.5836 56.5132C47.0462 56.8632 47.6565 56.7626 47.9556 56.2807C50.1595 52.7484 52.5874 49.1071 54.8979 45.4973C55.3605 44.7804 54.881 43.7875 54.0854 43.7875L54.0842 43.7862Z'
          fill='white'
        />
        <path
          d='M57.0707 51.6259C56.4362 50.9563 55.6709 50.6221 54.7917 50.6221C53.9126 50.6221 53.1521 50.9611 52.5115 51.6271C51.8709 52.2932 51.5573 53.1021 51.5646 54.0539C51.5718 55.0166 51.8927 55.8339 52.5164 56.4987C53.14 57.1636 53.8847 57.5002 54.7602 57.4978C55.6491 57.4954 56.4059 57.1611 57.0392 56.5C57.6713 55.8388 58.0019 55.0226 58.0128 54.0466C58.0237 53.1009 57.7113 52.2956 57.0719 51.6259H57.0707ZM55.6055 55.0456C55.3875 55.3023 55.1138 55.4319 54.7845 55.4331C54.4406 55.4331 54.1632 55.306 53.9465 55.0589C53.7297 54.8071 53.6207 54.4789 53.6195 54.0636C53.6195 53.6736 53.7322 53.3552 53.955 53.1093C54.1778 52.8575 54.4575 52.7352 54.7893 52.7339C55.1163 52.7339 55.3911 52.8611 55.6127 53.1069C55.8295 53.3576 55.9373 53.6761 55.9348 54.0599C55.9324 54.4595 55.8222 54.7889 55.6043 55.0456H55.6055Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_1459_31226'>
          <rect width='62' height='62' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default memo(ItemsDiscountIcon);
