import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// hocs
import AuthGuard from '@hocs/auth-guard';
import { CheckPermission } from '@hocs/check-permission';
// components
import { DashboardLayout } from '@atoms/layouts';
import { LoadingScreen } from '@atoms/loading-screen';
// routes
import { paths } from '../paths';
import { RequiredPerm } from '../permissions';

// ----------------------------------------------------------------------

// OVERVIEW
const PerfomancePage = lazy(() => import('@pages/operations/Perfomance'));
const FeedbackPage = lazy(() => import('@pages/operations/Feedback'));

// ----------------------------------------------------------------------

export const operationsRoutes = [
  {
    path: paths?.operations?.root,
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        element: (
          <CheckPermission
            permissionRequired={RequiredPerm?.Operations_Overview}
          >
            <PerfomancePage />
          </CheckPermission>
        ),
        index: true
      },
      {
        path: paths?.operations.reviews_and_rating,
        element: (
          <CheckPermission permissionRequired={RequiredPerm?.ReviewsAndRatings}>
            <FeedbackPage />
          </CheckPermission>
        )
      }
    ]
  }
];
