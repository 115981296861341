import { memo } from 'react';

function OrderCartIcon({ color }: any) {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='48'
        height='48'
        viewBox='0 0 48 48'
        fill='none'
      >
        <path
          d='M12.0098 18H39.8866L40.8866 14H16.0098V10H43.4482C44.5528 10 45.4482 10.8954 45.4482 12C45.4482 12.1635 45.4282 12.3264 45.3884 12.4851L40.3884 32.485C40.166 33.3754 39.366 34 38.4482 34H10.0098C8.90521 34 8.00977 33.1046 8.00977 32V8H4.00977V4H10.0098C11.1143 4 12.0098 4.89544 12.0098 6V18ZM12.0098 46C9.80063 46 8.00977 44.2092 8.00977 42C8.00977 39.7908 9.80063 38 12.0098 38C14.2189 38 16.0098 39.7908 16.0098 42C16.0098 44.2092 14.2189 46 12.0098 46ZM36.0098 46C33.8006 46 32.0098 44.2092 32.0098 42C32.0098 39.7908 33.8006 38 36.0098 38C38.219 38 40.0098 39.7908 40.0098 42C40.0098 44.2092 38.219 46 36.0098 46Z'
          fill={color}
        />
      </svg>
    </>
  );
}

export default memo(OrderCartIcon);
