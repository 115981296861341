import { memo } from 'react';

function RevenueCampaignIcon({ color }: any) {
  return (
    <>
      <svg
        width='48'
        height='48'
        viewBox='0 0 48 48'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M7.80291 35.7274L15.625 27.9054L21.2818 33.5622L30.424 24.42L34.0098 28.0058V18.0059H24.0098L27.5956 21.5916L21.2818 27.9054L15.625 22.2486L5.73325 32.1402C4.62553 29.6552 4.00977 26.9024 4.00977 24.0058C4.00977 12.9602 12.9641 4.00586 24.0098 4.00586C35.0554 4.00586 44.0098 12.9602 44.0098 24.0058C44.0098 35.0516 35.0554 44.0058 24.0098 44.0058C17.3423 44.0058 11.4369 40.7432 7.80291 35.7274Z'
          fill={color}
        />
      </svg>
    </>
  );
}

export default memo(RevenueCampaignIcon);
