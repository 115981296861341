import { memo } from 'react';

function OrderIncreaseIcon({ color }: any) {
  return (
    <>
      <svg
        width='48'
        height='48'
        viewBox='0 0 48 48'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M39.8866 18H12.0098V6C12.0098 4.89544 11.1143 4 10.0098 4H4.00977V8H8.00977V32C8.00977 33.1046 8.90521 34 10.0098 34H38.4482C39.366 34 40.166 33.3754 40.3884 32.485L45.3884 12.4851C45.4282 12.3264 45.4482 12.1635 45.4482 12C45.4482 10.8954 44.5528 10 43.4482 10H16.0098V14H40.8866L39.8866 18ZM8.00977 42C8.00977 44.2092 9.80063 46 12.0098 46C14.2189 46 16.0098 44.2092 16.0098 42C16.0098 39.7908 14.2189 38 12.0098 38C9.80063 38 8.00977 39.7908 8.00977 42ZM32.0098 42C32.0098 44.2092 33.8006 46 36.0098 46C38.219 46 40.0098 44.2092 40.0098 42C40.0098 39.7908 38.219 38 36.0098 38C33.8006 38 32.0098 39.7908 32.0098 42ZM22.4846 27.1699L20.1379 29.5165C21.2281 31.0212 22.9998 32 25 32C28.3137 32 31 29.3137 31 26C31 22.6863 28.3137 20 25 20C21.6863 20 19 22.6863 19 26C19 26.869 19.1847 27.6948 19.517 28.4403L22.4846 25.4728L24.1816 27.1699L26.0757 25.2757L25 24.2H28V27.2L26.9243 26.1243L24.1816 28.8669L22.4846 27.1699Z'
          fill={color}
        />
      </svg>
    </>
  );
}

export default memo(OrderIncreaseIcon);
