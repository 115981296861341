/* eslint-disable no-console */
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// routes
import { paths } from '@routes/paths';
import { RequiredPerm } from '@routes/permissions';
// utils
import { useAuthContext } from '@context/auth/use-auth-context';
import { useRouter } from '@hooks/routes';
// api
import {
  fetchAreas,
  fetchChannels,
  fetchCities,
  fetchCuisines,
  fetchLocations
} from '@api/index';
// actions
import {
  setAreas,
  setBrands,
  setChannels,
  setCities,
  setCuisines,
  setLocations
} from '@store/slices/FilterSlice';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function AuthGuard({ children }: Props) {
  const router = useRouter();
  const dispatch = useDispatch();

  const { user, authenticated } = useAuthContext();
  const isGrowIntelEnable =
    user?.isCustomerSuccessUser ||
    user?.features?.some((perm: string) =>
      RequiredPerm?.GrowIntel?.includes(perm)
    );

  const [checked, setChecked] = useState(false);

  const check = useCallback(() => {
    if (!authenticated) {
      router.replace(paths?.auth?.jwt?.login);
    } else {
      setChecked(true);
    }
  }, [authenticated, router]);

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          channelsData,
          locationsData,
          citiesData,
          areasData,
          cuisinesData
        ] = await Promise.all([
          fetchChannels(),
          fetchLocations(),
          fetchCities(
            isGrowIntelEnable,
            user?.country_name === 'Romania' ? 'glovo' : undefined
          ),
          fetchAreas(isGrowIntelEnable),
          fetchCuisines(
            isGrowIntelEnable,
            user?.country_name === 'Romania' ? 'glovo' : undefined
          )
        ]);
        dispatch(setLocations(locationsData));
        dispatch(setChannels(channelsData));
        dispatch(setBrands(user?.brands));
        dispatch(setCities(citiesData));
        dispatch(setAreas(areasData));
        dispatch(setCuisines(cuisinesData));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [authenticated, dispatch, isGrowIntelEnable, user]);

  if (!checked) {
    return null;
  }

  return <>{children}</>;
}
