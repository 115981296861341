import { memo } from 'react';

function GrossSalesIcon({ color }: any) {
  return (
    <>
      <svg
        width='48'
        height='48'
        viewBox='0 0 48 48'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M6 28.8888H16.8V43.9999H6V28.8888ZM18.6 7H29.4V43.9999H18.6V7ZM31.2 16H42V43.9999H31.2V16Z'
          fill={color}
        />
        <circle cx='11.3875' cy='21.6125' r='5.38745' fill={color} />
        <path
          d='M13.0336 24.3064C12.4551 24.3064 11.9861 23.8374 11.9861 23.2588C11.9861 22.6803 12.4551 22.2113 13.0336 22.2113C13.6122 22.2113 14.0812 22.6803 14.0812 23.2588C14.0812 23.8374 13.6122 24.3064 13.0336 24.3064ZM9.74128 21.0141C9.16274 21.0141 8.69373 20.5451 8.69373 19.9665C8.69373 19.388 9.16274 18.9189 9.74128 18.9189C10.3198 18.9189 10.7888 19.388 10.7888 19.9665C10.7888 20.5451 10.3198 21.0141 9.74128 21.0141ZM13.5039 19.073L13.9271 19.4963L9.27106 24.1523L8.84778 23.7291L13.5039 19.073Z'
          fill='white'
        />
      </svg>
    </>
  );
}

export default memo(GrossSalesIcon);
