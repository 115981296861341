import { useMemo } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// context
import { useAuthContext } from '@context/auth/use-auth-context';
// config
import PostHogPageViewTracker from '@integrations/postHog/PosthogPageViewTracker';
// routes
import { authRoutes } from './modules/auth';
import { campaignRoutes } from './modules/campaigns';
import { dashboardRoutes, oldDashboardRoutes } from './modules/dashboard';
import { growIntelRoutes } from './modules/grow-intel';
import { growRomaniaRoutes } from './modules/grow-intel-romania';
import { growSocialRoutes } from './modules/grow-social';
import { mainRoutes } from './modules/main';
import { managementRoutes } from './modules/management';
import { operationsRoutes } from './modules/operations';
import { paths } from './paths';
// ----------------------------------------------------------------------

export default function Router() {
  PostHogPageViewTracker();
  const { authenticated, user } = useAuthContext();
  const growIntRoutes = useMemo(
    () =>
      user?.country_name === 'Romania' ? growRomaniaRoutes : growIntelRoutes,
    [user?.country_name]
  );

  return useRoutes([
    // SET INDEX PAGE WITH SKIP HOME PAGE
    {
      path: '/',
      element: (
        <Navigate
          to={authenticated ? paths?.campaigns?.root : paths?.auth?.jwt?.login}
          replace
        />
      )
    },

    // ----------------------------------------------------------------------

    // Auth routes
    ...authRoutes,

    // Dashboard routes
    ...dashboardRoutes,
    ...oldDashboardRoutes,

    // GrowSocial routes
    ...growSocialRoutes,

    // GrowIntel routes
    ...growIntRoutes,

    // Marketing routes
    ...campaignRoutes,

    // Management routes
    ...managementRoutes,

    // Operations routes
    ...operationsRoutes,

    // Explore routes
    // ...exploreRoutes,

    // Main routes
    ...mainRoutes,

    // No match 404
    { path: '*', element: <Navigate to='/404' replace /> }
  ]);
}
