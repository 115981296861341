import SvgColor from '@atoms/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  ads: icon('ic_ads'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  operations: icon('ic_operations'),
  intel: icon('ic_intel'),
  marketChange: icon('ic_market_change'),
  popularBrands: icon('ic_popular_brands'),
  popularItems: icon('ic_popular_items'),
  itemSearch: icon('ic_item_search'),
  competitors: icon('ic_job'),
  menuSuggest: icon('ic_menu_suggestion'),
  menuAnalysis: icon('ic_menu_analysis'),
  academy: icon('ic_academy')
};

export default ICONS;
