import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// components
import { CompactLayout, SimpleLayout } from '@atoms/layouts';
import { SplashScreen } from '@atoms/loading-screen';

// ----------------------------------------------------------------------

const Page500 = lazy(() => import('@pages/errors/500'));
const Page403 = lazy(() => import('@pages/errors/403'));
const NotFoundPage = lazy(() => import('@pages/errors/404'));

// ----------------------------------------------------------------------

export const mainRoutes = [
  {
    element: (
      <SimpleLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </SimpleLayout>
    )
  },
  {
    element: (
      <CompactLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </CompactLayout>
    ),
    children: [
      { path: '500', element: <Page500 /> },
      { path: '404', element: <NotFoundPage /> },
      { path: '403', element: <Page403 /> }
    ]
  }
];
