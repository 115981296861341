import { useLocation, Navigate } from 'react-router-dom';
import { useAuthContext } from '@context/auth/use-auth-context';

// ----------------------------------------------------------------------

interface CheckPermProps {
  children: React.ReactNode;
  permissionRequired: string[];
  isGrowIntelEnable?: boolean;
}

export function CheckPermission({
  children,
  permissionRequired,
  isGrowIntelEnable = false
}: CheckPermProps) {
  const { user } = useAuthContext();
  const location = useLocation();

  if (
    permissionRequired?.length > 0 &&
    !user?.isCustomerSuccessUser &&
    !user?.permissions?.some((perm: string) =>
      permissionRequired?.includes(perm)
    ) &&
    !isGrowIntelEnable
  ) {
    return <Navigate to='/unauthorized' state={{ form: location }} replace />;
  }
  return <>{children}</>;
}
