import { memo } from 'react';

function AvgRatingIcon({ color }: any) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
    >
      <g clipPath='url(#clip0_3723_46874)'>
        <path
          d='M38.8088 26.2522L33.7259 31.2071L34.9259 38.203C35.3742 40.8212 32.6241 42.809 30.2829 41.576L24 38.273L17.7171 41.576C15.3813 42.8081 12.6253 40.825 13.0742 38.203L14.2742 31.2071L9.19129 26.2522C7.29242 24.403 8.3415 21.1746 10.9643 20.7943L17.9891 19.7733L21.1301 13.4084C22.3003 11.0378 25.6904 11.0233 26.87 13.4084L30.0109 19.7733L37.0358 20.7943C39.659 21.1746 40.7075 24.4032 38.8088 26.2522Z'
          fill={color}
        />
        <path
          d='M47.2707 17.136L43.3917 20.917L44.3077 26.2559C44.645 28.2237 42.5734 29.7207 40.8138 28.7939L39.3068 28.0019C39.8761 27.4473 40.1464 27.2095 40.4508 26.7959C42.5771 23.9052 40.8667 19.734 37.2658 19.212L31.0739 18.312L28.308 12.7081L31.4629 12.2501L33.8599 7.39221C34.7434 5.60354 37.2954 5.60374 38.1788 7.39221L40.5758 12.2501L45.9367 13.0291C47.9068 13.3139 48.701 15.7403 47.2707 17.136Z'
          fill={color}
        />
        <path
          d='M19.6921 12.7081L16.9262 18.312L10.7343 19.212C6.79876 19.7825 5.22739 24.6254 8.07434 27.3988L8.69333 28.0018L7.18635 28.7938C5.42058 29.7231 3.35542 28.2215 3.69241 26.2559L4.6084 20.9169L0.729462 17.136C-0.701614 15.7396 0.0935724 13.3137 2.06444 13.0291L7.42435 12.2501L9.82131 7.39217C10.7048 5.6036 13.2568 5.6037 14.1402 7.39217L16.5372 12.2501L19.6921 12.7081Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_3723_46874'>
          <rect width='48' height='48' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default memo(AvgRatingIcon);
