import { memo } from 'react';

function DelayedOrderIcon({ color }: any) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
    >
      <g clipPath='url(#clip0_3723_46635)'>
        <path
          d='M35 22C27.832 22 22 27.832 22 35C22 42.168 27.832 48 35 48C42.168 48 48 42.168 48 35C48 27.832 42.168 22 35 22ZM40.184 40.42C39.89 40.8 39.446 41 39 41C38.678 41 38.354 40.896 38.08 40.684L33.58 37.184C33.214 36.9 33 36.462 33 36V29.5C33 28.672 33.672 28 34.5 28C35.328 28 36 28.672 36 29.5V35.266L39.92 38.316C40.576 38.824 40.692 39.766 40.184 40.42Z'
          fill={color}
        />
        <path
          d='M18.44 0L8.71998 0.04C7.45998 0.04 6.27998 0.74 5.67998 1.84L0.599976 11.04L17.46 11L18.44 0Z'
          fill={color}
        />
        <path
          d='M39.3599 10.9609L34.2199 1.76094C33.5999 0.660937 32.4399 -0.0390625 31.1599 -0.0390625L21.4399 0.000937499L22.4599 11.0009L39.3599 10.9609Z'
          fill={color}
        />
        <path
          d='M40 18.7414C38.42 18.2614 36.74 18.0014 35 18.0014C25.62 18.0014 18 25.6214 18 35.0014C18 36.7414 18.26 38.4414 18.76 40.0214L3.21998 40.0614C3.19998 40.0614 3.19998 40.0614 3.19998 40.0614C1.47998 40.0614 0.0599805 38.6414 0.0599805 36.9014L-0.0200195 14.0414L18.48 14.0014H21.48L39.98 13.9414L40 18.7414Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_3723_46635'>
          <rect width='48' height='48' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default memo(DelayedOrderIcon);
