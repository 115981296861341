import { forwardRef } from 'react';
// @mui
import Box, { BoxProps } from '@mui/material/Box';
import Link from '@mui/material/Link';
// routes
import RouterLink from '@atoms/routerLink';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx }) => {
    // OR using local (public folder)
    // -------------------------------------------------------
    const logo = (
      <Box
        component='img'
        src='/logo/2.png'
        sx={{ width: 190, height: 50, cursor: 'pointer', ...sx }}
      />
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} href='/' sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
