import { memo } from 'react';

function PrepTimeIcon({ color }: any) {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='48'
        height='48'
        viewBox='0 0 48 48'
        fill='none'
      >
        <path
          d='M39.5625 29.1562C34.9026 29.1562 31.125 32.9338 31.125 37.5938C31.125 42.2537 34.9026 46.0312 39.5625 46.0312C44.2224 46.0312 48 42.2537 48 37.5938C48 32.9338 44.2224 29.1562 39.5625 29.1562ZM40.9688 37.5938C40.9688 38.3704 40.3391 39 39.5625 39H36.75C35.9734 39 35.3438 38.3704 35.3438 37.5938C35.3438 36.8171 35.9734 36.1875 36.75 36.1875H38.1562V34.7812C38.1562 34.0046 38.7859 33.375 39.5625 33.375C40.3391 33.375 40.9688 34.0046 40.9688 34.7812V37.5938Z'
          fill={color}
        />
        <path
          d='M25.7283 5.09316C25.4304 3.32006 23.889 1.96875 22.0313 1.96875C20.1735 1.96875 18.6322 3.32006 18.3342 5.09316C19.5367 4.88906 20.7715 4.78125 22.0313 4.78125C23.291 4.78125 24.5259 4.88906 25.7283 5.09316Z'
          fill={color}
        />
        <path
          d='M0 32.4375C0 34.7675 1.88878 36.6562 4.21875 36.6562H28.3519C28.5249 34.5686 29.2703 32.6411 30.4305 31.0312H1.40625C0.629625 31.0312 0 31.6609 0 32.4375Z'
          fill={color}
        />
        <path
          d='M39.5625 26.3438C40.1345 26.3438 40.6964 26.387 41.2457 26.4697C41.0619 16.0301 32.5139 7.59375 22.0312 7.59375C11.434 7.59375 2.8125 16.2153 2.8125 26.8125V28.2188H33.3512C35.1323 27.0348 37.2681 26.3438 39.5625 26.3438Z'
          fill={color}
        />
      </svg>
    </>
  );
}

export default memo(PrepTimeIcon);
