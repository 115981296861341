import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
// TODO: add also https://github.com/i18next/i18next-localstorage-backend
// to persist resources in localstorage and firslty chekck for it
// and then make request for 3d party service

// Import all translation files
// import translationEnglish from "./Translation/English/translation.json";
// import translationSpanish from "./Translation/Spanish/translation.json";
// import translationFrench from "./Translation/French/translation.json";

// ---Using translation
// const resources = {
//     en: {
//         translation: translationEnglish,
//     },
//     es: {
//         translation: translationSpanish,
//     },
//     fr: {
//         translation: translationFrench,
//     },
// }

// ---Using different namespaces
const resources = {
  // en: {
  //     common: translationEnglish,
  // },
  // es: {
  //     common: translationSpanish,
  // },
  // fr: {
  //     common: translationFrench,
  // },
};

// Add names for each locale to
// show the user in our locale
// switcher.
export const supportedLngs = {
  en: 'English',
  ar: 'Arabic (العربية)'
};

i18next
  // Wire up the backend as a plugin.
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    // Specifies the default language (locale) used
    // when a user visits our site for the first time.
    // We need to remove this explicit setting
    // of the the active locale, or it will
    // override the auto-detected locale.
    // lng: 'en',

    // Fallback locale used when a translation is
    // missing in the active locale. Again, use your
    // preferred locale here.
    fallbackLng: 'en',

    // Explicitly tell i18next our
    // supported locales.
    supportedLngs: Object.keys(supportedLngs),

    // Enables useful output in the browser’s
    // dev console.
    debug: true,

    // Normally, we want `escapeValue: true` as it
    // ensures that i18next escapes any code in
    // translation messages, safeguarding against
    // XSS (cross-site scripting) attacks. However,
    // React does this escaping itself, so we turn
    // it off in i18next.
    interpolation: {
      escapeValue: false
    },
    backend: {
      // path where resources get loaded from, or a function
      // returning a path:
      // function(lngs, namespaces) { return customPath; }
      // the returned path will interpolate lng, ns if provided like giving a static path
      loadPath: '/locales/{{lng}}/{{ns}}.json',

      // path to post missing resources
      addPath: '/locales/add/{{lng}}/{{ns}}',

      // your backend server supports multiloading
      // /locales/resources.json?lng=de+en&ns=ns1+ns2
      // Adapter is needed to enable MultiLoading https://github.com/i18next/i18next-multiload-backend-adapter
      // Returned JSON structure in this case is
      // {
      //  lang : {
      //   namespaceA: {},
      //   namespaceB: {},
      //   ...etc
      //  }
      // }
      allowMultiLoading: false, // set loadPath: '/locales/resources.json?lng={{lng}}&ns={{ns}}' to adapt to multiLoading

      // parse data after it has been fetched
      // in example use https://www.npmjs.com/package/json5
      // here it removes the letter a from the json (bad idea)
      // parse: function(data) { return data.replace(/a/g, ''); },

      // parse data before it has been sent by addPath
      // parsePayload: function(namespace, key, fallbackValue) { return { key } },

      // allow cross domain requests
      crossDomain: false,

      // allow credentials on cross domain requests
      withCredentials: false,

      // overrideMimeType sets request.overrideMimeType("application/json")
      overrideMimeType: false,

      // custom request headers sets request.setRequestHeader(key, value)
      customHeaders: {
        authorization: 'foo'
        // ...
      },

      requestOptions: {
        // used for fetch, can also be a function (payload) => ({ method: 'GET' })
        mode: 'cors',
        credentials: 'same-origin',
        cache: 'default'
      },

      // define a custom request function
      // can be used to support XDomainRequest in IE 8 and 9
      //
      // 'url' will be passed the value of 'loadPath'
      // 'options' will be this entire options object
      // 'callback' is a function that takes two parameters, 'data' and 'HttpApi'.
      //            'data' should be the key:value translation pairs for the
      //            requested language and namespace, or null in case of an error.
      //            'HttpApi' should be a status object, e.g. { status: 200 }
      // 'payload' will be a key:value object used when saving missing translations
      // request: function (options, url, payload, callback) {},

      // adds parameters to resource URL. 'example.com' -> 'example.com?v=1.3.5'
      queryStringParams: { v: '1.3.5' },

      reloadInterval: false // can be used to reload resources in a specific interval (useful in server environments)
    }
  });

export default i18next;
