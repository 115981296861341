import { memo } from 'react';

function DiscountOrderRevenueIcon({ color }: any) {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='48'
        height='48'
        viewBox='0 0 48 48'
        fill='none'
      >
        <path
          d='M12.0098 18H39.8866L40.8866 14H16.0098V10H43.4482C44.5528 10 45.4482 10.8954 45.4482 12C45.4482 12.1635 45.4282 12.3264 45.3884 12.4851L40.3884 32.485C40.166 33.3754 39.366 34 38.4482 34H10.0098C8.90521 34 8.00977 33.1046 8.00977 32V8H4.00977V4H10.0098C11.1143 4 12.0098 4.89544 12.0098 6V18ZM12.0098 46C9.80063 46 8.00977 44.2092 8.00977 42C8.00977 39.7908 9.80063 38 12.0098 38C14.2189 38 16.0098 39.7908 16.0098 42C16.0098 44.2092 14.2189 46 12.0098 46ZM36.0098 46C33.8006 46 32.0098 44.2092 32.0098 42C32.0098 39.7908 33.8006 38 36.0098 38C38.219 38 40.0098 39.7908 40.0098 42C40.0098 44.2092 38.219 46 36.0098 46Z'
          fill={color}
        />
        <path
          d='M30 26V27.25C30 29.3211 27.5376 31 24.5 31C21.5167 31 19.0881 29.3805 19.0023 27.3606L19 27.25V26C19 28.0711 21.4624 29.75 24.5 29.75C27.5376 29.75 30 28.0711 30 26ZM24.5 21C27.5376 21 30 22.6789 30 24.75C30 26.8211 27.5376 28.5 24.5 28.5C21.4624 28.5 19 26.8211 19 24.75C19 22.6789 21.4624 21 24.5 21Z'
          fill='white'
        />
        <path
          d='M25.7259 26.9995C25.2963 26.9995 24.9481 26.6513 24.9481 26.2217C24.9481 25.7922 25.2963 25.444 25.7259 25.444C26.1554 25.444 26.5037 25.7922 26.5037 26.2217C26.5037 26.6513 26.1554 26.9995 25.7259 26.9995ZM23.2814 24.5551C22.8519 24.5551 22.5037 24.2068 22.5037 23.7773C22.5037 23.3477 22.8519 22.9995 23.2814 22.9995C23.711 22.9995 24.0592 23.3477 24.0592 23.7773C24.0592 24.2068 23.711 24.5551 23.2814 24.5551ZM26.075 23.1139L26.3893 23.4282L22.9323 26.8851L22.618 26.5709L26.075 23.1139Z'
          fill={color}
        />
      </svg>
    </>
  );
}

export default memo(DiscountOrderRevenueIcon);
