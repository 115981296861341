import { memo } from 'react';

function AvgBasketIcon({ color }: any) {
  return (
    <>
      <svg
        width='48'
        height='48'
        viewBox='0 0 48 48'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M12.0098 18H39.8866L40.8866 14H16.0098V10H43.4482C44.5528 10 45.4482 10.8954 45.4482 12C45.4482 12.1635 45.4282 12.3264 45.3884 12.4851L40.3884 32.485C40.166 33.3754 39.366 34 38.4482 34H10.0098C8.90521 34 8.00977 33.1046 8.00977 32V8H4.00977V4H10.0098C11.1143 4 12.0098 4.89544 12.0098 6V18ZM12.0098 46C9.80063 46 8.00977 44.2092 8.00977 42C8.00977 39.7908 9.80063 38 12.0098 38C14.2189 38 16.0098 39.7908 16.0098 42C16.0098 44.2092 14.2189 46 12.0098 46ZM36.0098 46C33.8006 46 32.0098 44.2092 32.0098 42C32.0098 39.7908 33.8006 38 36.0098 38C38.219 38 40.0098 39.7908 40.0098 42C40.0098 44.2092 38.219 46 36.0098 46Z'
          fill={color}
        />
        <path
          d='M24.2539 27.6011V24.1906H25.0118V27.6011H24.2539Z'
          fill='white'
        />
        <path
          d='M26.5218 27.6003H25.7639V25.3267H26.5218V27.6003Z'
          fill='white'
        />
        <path
          d='M23.4947 26.4628V27.5996H22.7368V26.4628H23.4947Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M18.9473 25.8947C18.9473 22.7554 21.4922 20.2105 24.6315 20.2105C27.7708 20.2105 30.3157 22.7554 30.3157 25.8947C30.3157 29.034 27.7708 31.5789 24.6315 31.5789C21.4922 31.5789 18.9473 29.034 18.9473 25.8947ZM24.2525 23.4316C23.834 23.4316 23.4946 23.7709 23.4946 24.1895V25.7052H22.7367C22.3182 25.7052 21.9788 26.0446 21.9788 26.4631V27.9789C21.9788 28.1882 22.1485 28.3579 22.3578 28.3579H26.9052C27.1145 28.3579 27.2841 28.1882 27.2841 27.9789V25.3263C27.2841 24.9077 26.9448 24.5684 26.5262 24.5684H25.7683V24.1895C25.7683 23.7709 25.429 23.4316 25.0104 23.4316H24.2525Z'
          fill='white'
        />
      </svg>
    </>
  );
}

export default memo(AvgBasketIcon);
