import { configureStore } from '@reduxjs/toolkit';
import filterReducer from '@store/slices/FilterSlice';
import calendarReducer from '@store/slices/CalendarSlice';

export const store = configureStore({
  reducer: {
    filter: filterReducer,
    calendar: calendarReducer
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
