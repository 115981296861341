// @mui
import LockIcon from '@mui/icons-material/Lock'; // Import the lock icon
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
// components
import { Iconify } from '@atoms/iconify';
import RouterLink from '@atoms/routerLink';
//
import { NavConfigProps, NavItemProps } from '../types';
import { StyledDotIcon, StyledIcon, StyledItem } from './styles';

// ----------------------------------------------------------------------

type Props = NavItemProps & {
  config: NavConfigProps;
  disabled?: boolean;
};

export default function NavItem({
  item,
  open,
  depth,
  active,
  config,
  disabled = false,
  externalLink,
  ...other
}: Props) {
  const { title, path, icon, info, children, caption, roles } = item;

  const subItem = depth !== 1;

  const renderContent = (
    <StyledItem
      disableGutters
      active={active}
      depth={depth}
      config={config}
      {...other}
    >
      <>
        {icon && <StyledIcon size={config.iconSize}>{icon}</StyledIcon>}

        {subItem && (
          <StyledIcon size={config.iconSize}>
            <StyledDotIcon active={active} />
          </StyledIcon>
        )}
      </>

      {!(config.hiddenLabel && !subItem) && (
        <ListItemText
          primary={
            <>
              {title}
              {disabled && (
                <LockIcon
                  sx={{ fontSize: 16, marginLeft: 0.5, marginBottom: -0.2 }}
                />
              )}
              {/* Conditionally render the lock icon */}
            </>
          }
          secondary={
            caption ? (
              <Tooltip title={caption} placement='top-start'>
                <span>{caption}</span>
              </Tooltip>
            ) : null
          }
          primaryTypographyProps={{
            noWrap: true,
            typography: 'body2',
            textTransform: 'capitalize',
            fontWeight: active ? 'fontWeightSemiBold' : 'fontWeightMedium'
          }}
          // secondaryTypographyProps={{
          //   noWrap: true,
          //   component: 'span',
          //   typography: 'caption',
          //   color: 'text.disabled',
          // }}
        />
      )}

      {info && (
        <Box component='span' sx={{ ml: 1, lineHeight: 0 }}>
          {info}
        </Box>
      )}

      {!!children && (
        <Iconify
          width={16}
          icon={
            open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'
          }
          sx={{ ml: 1, flexShrink: 0 }}
        />
      )}
    </StyledItem>
  );

  // Hidden item by role
  if (roles && !roles.includes(`${config.currentRole}`)) {
    return null;
  }

  // External link
  if (externalLink)
    return (
      <Link
        href={path}
        target='_blank'
        rel='noopener'
        underline='none'
        color='inherit'
      >
        {renderContent}
      </Link>
    );

  // Has child
  if (children) {
    return renderContent;
  }

  // Default
  return (
    <Link component={RouterLink} href={path} underline='none' color='inherit'>
      {renderContent}
    </Link>
  );
}
