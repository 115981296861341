import { memo } from 'react';

function AdsSpendIcon({ color }: any) {
  return (
    <>
      <svg
        width='48'
        height='48'
        viewBox='0 0 48 48'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M34.2075 16.3875H38.9775C39.8025 16.3875 40.4775 15.7125 40.4775 14.8875C40.4775 14.0625 39.8025 13.3875 38.9775 13.3875H34.2075C33.3825 13.3875 32.7075 14.0625 32.7075 14.8875C32.7075 15.7125 33.3825 16.3875 34.2075 16.3875Z'
          fill={color}
        />
        <path
          d='M34.2074 10.185C34.4624 10.185 34.7249 10.1175 34.9649 9.97499L38.7299 7.76249C39.4424 7.34249 39.6824 6.41999 39.2624 5.70749C38.8424 4.99499 37.9199 4.75499 37.2074 5.17499L33.4499 7.39499C32.7374 7.81499 32.4974 8.73749 32.9174 9.44999C33.1949 9.92249 33.6974 10.185 34.2074 10.185Z'
          fill={color}
        />
        <path
          d='M34.365 17.955C32.655 17.955 31.0275 18.285 29.52 18.8625V4.2525C29.52 3.7875 29.3025 3.345 28.9275 3.06C28.5525 2.775 28.065 2.685 27.615 2.8125L7.38 8.51999H6.2775C2.82 8.51999 0 11.3775 0 14.895C0 16.5975 0.6525 18.195 1.8225 19.38C3.0075 20.595 4.59 21.2625 6.2775 21.2625H7.395L8.4525 21.555V24.0525C8.4525 26.055 9.78 27.825 11.6775 28.3575L14.7075 29.2125C15.105 29.325 15.51 29.385 15.9075 29.385C16.845 29.385 17.76 29.0775 18.54 28.4925C19.665 27.6375 20.31 26.3325 20.31 24.9075L22.2075 25.44C21.27 27.2925 20.7225 29.385 20.7225 31.605C20.7225 39.1275 26.8425 45.2475 34.365 45.2475C41.88 45.2475 48 39.1275 48 31.605C48 24.075 41.88 17.955 34.365 17.955ZM17.31 24.9075C17.31 25.3875 17.1 25.8225 16.7325 26.1C16.5225 26.2575 16.095 26.49 15.525 26.325L12.495 25.4775C11.88 25.2975 11.4525 24.72 11.4525 24.0525V22.4025L17.31 24.06V24.9075ZM34.365 42.2475C28.5 42.2475 23.7225 37.47 23.7225 31.605C23.7225 25.7325 28.5 20.955 34.365 20.955C40.23 20.955 45 25.7325 45 31.605C45 37.47 40.23 42.2475 34.365 42.2475Z'
          fill={color}
        />
        <path
          d='M32.6175 28.35H38.475C39.3 28.35 39.975 27.675 39.975 26.85C39.975 26.025 39.3 25.35 38.475 25.35H35.8575V24.5025C35.8575 23.6775 35.1825 23.0025 34.3575 23.0025C33.5325 23.0025 32.8575 23.6775 32.8575 24.5025V25.35H32.6175C30.48 25.35 28.74 27.09 28.74 29.2275C28.74 31.365 30.48 33.105 32.6175 33.105H34.3575H36.105C36.585 33.105 36.9825 33.495 36.9825 33.9825C36.9825 34.47 36.5925 34.86 36.105 34.86H30.2475C29.4225 34.86 28.7475 35.535 28.7475 36.36C28.7475 37.185 29.4225 37.86 30.2475 37.86H32.865V38.7075C32.865 39.5325 33.54 40.2075 34.365 40.2075C35.19 40.2075 35.865 39.5325 35.865 38.7075V37.86H36.1125C38.25 37.86 39.99 36.12 39.99 33.9825C39.99 31.845 38.25 30.105 36.1125 30.105H34.365H32.625C32.145 30.105 31.7475 29.715 31.7475 29.2275C31.7475 28.74 32.1375 28.35 32.6175 28.35Z'
          fill={color}
        />
      </svg>
    </>
  );
}

export default memo(AdsSpendIcon);
