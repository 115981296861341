import { memo } from 'react';

function NetRevenueIncreaseIcon() {
  return (
    <>
      <svg
        width='48'
        height='48'
        viewBox='0 0 48 48'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10.0011 25.9996C10.0011 28.0202 10.23 29.9826 10.6611 31.8574L6.62084 35.4488C6.26142 35.7682 6.18028 36.2984 6.4277 36.7106L11.3421 44.9014C11.3842 44.9714 11.4347 45.0362 11.4925 45.094C11.883 45.4846 12.5162 45.4846 12.9067 45.094L17.4153 40.5854C17.7904 40.2104 18.2991 39.9996 18.8295 39.9996H29.1726C29.703 39.9996 30.2118 40.2104 30.5868 40.5854L35.0954 45.094C35.1532 45.1518 35.218 45.2024 35.288 45.2444C35.7616 45.5286 36.3758 45.375 36.66 44.9014L41.5744 36.7106C41.8218 36.2984 41.7408 35.7682 41.3814 35.4488L37.3412 31.8574C37.7722 29.9826 38.001 28.0202 38.001 25.9996C38.001 15.8237 32.1958 7.12696 24.001 3.63574C15.8064 7.12696 10.0011 15.8237 10.0011 25.9996ZM28.001 21.9996C28.001 24.2088 26.2102 25.9996 24.001 25.9996C21.792 25.9996 20.001 24.2088 20.001 21.9996C20.001 19.7905 21.792 17.9996 24.001 17.9996C26.2102 17.9996 28.001 19.7905 28.001 21.9996ZM18 22C18 25.3137 20.6863 28 24 28C27.3137 28 30 25.3137 30 22C30 18.6863 27.3137 16 24 16C20.6863 16 18 18.6863 18 22ZM21.9 24.4V23.2H25.2C25.3657 23.2 25.5 23.0657 25.5 22.9C25.5 22.7343 25.3657 22.6 25.2 22.6H22.8C21.9716 22.6 21.3 21.9284 21.3 21.1C21.3 20.2716 21.9716 19.6 22.8 19.6H23.4V18.4H24.6V19.6H26.1V20.8H22.8C22.6343 20.8 22.5 20.9343 22.5 21.1C22.5 21.2657 22.6343 21.4 22.8 21.4H25.2C26.0284 21.4 26.7 22.0716 26.7 22.9C26.7 23.7284 26.0284 24.4 25.2 24.4H24.6V25.6H23.4V24.4H21.9Z'
          fill='#FFCC47'
        />
      </svg>
    </>
  );
}

export default memo(NetRevenueIncreaseIcon);
