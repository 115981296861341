import { memo } from 'react';

function RevenueIcon({ color }: any) {
  return (
    <>
      <svg
        width='42'
        height='36'
        viewBox='0 0 42 36'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M40.0098 6H26.0098C19.3824 6 14.0098 11.3726 14.0098 18C14.0098 24.6274 19.3824 30 26.0098 30H40.0098V34C40.0098 35.1046 39.1144 36 38.0098 36H2.00977C0.905206 36 0.00976562 35.1046 0.00976562 34V2C0.00976562 0.89544 0.905206 0 2.00977 0H38.0098C39.1144 0 40.0098 0.89544 40.0098 2V6ZM26.0098 10H42.0098V26H26.0098C21.5914 26 18.0098 22.4182 18.0098 18C18.0098 13.5817 21.5914 10 26.0098 10ZM26.0098 16V20H32.0098V16H26.0098Z'
          fill={color}
        />
      </svg>
    </>
  );
}

export default memo(RevenueIcon);
