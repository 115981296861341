import { memo } from 'react';

function AllCustomerIcon() {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='48'
        height='48'
        viewBox='0 0 48 48'
        fill='none'
      >
        <path
          d='M18.002 20C22.4202 20 26.002 16.4183 26.002 12C26.002 7.58172 22.4202 4 18.002 4C13.5837 4 10.002 7.58172 10.002 12C10.002 16.4183 13.5837 20 18.002 20Z'
          fill='#02D2C5'
        />
        <path
          d='M18.002 42.002C25.7339 42.002 32.002 38.4203 32.002 34.002C32.002 29.5837 25.7339 26.002 18.002 26.002C10.27 26.002 4.00195 29.5837 4.00195 34.002C4.00195 38.4203 10.27 42.002 18.002 42.002Z'
          fill='#02D2C5'
        />
        <path
          d='M42 34C42 37.314 37.928 40 32.958 40C34.422 38.4 35.43 36.39 35.43 34.004C35.43 31.614 34.42 29.604 32.952 28.002C37.924 28 42 30.688 42 34ZM36 12C36.0006 12.9654 35.7683 13.9168 35.3227 14.7732C34.8771 15.6297 34.2314 16.366 33.4405 16.9196C32.6495 17.4732 31.7367 17.8277 30.7794 17.9531C29.8222 18.0786 28.8488 17.9711 27.942 17.64C28.919 15.9211 29.4312 13.9772 29.428 12C29.428 9.95 28.888 8.026 27.944 6.362C28.8507 6.03126 29.8239 5.92416 30.7808 6.04978C31.7378 6.17539 32.6504 6.53003 33.4411 7.08358C34.2317 7.63713 34.8772 8.37326 35.3227 9.2295C35.7681 10.0857 36.0005 11.0348 36 12Z'
          fill='#02D2C5'
        />
      </svg>
    </>
  );
}

export default memo(AllCustomerIcon);
