/* eslint-disable no-param-reassign */
import { useEffect } from 'react';

// Function to load Google Translate script
const loadGoogleTranslateScript = (callback: () => void) => {
  const script = document.createElement('script');
  script.src =
    'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
  script.async = true;
  script.onload = callback;
  document.body.appendChild(script);
};

// Function to initialize Google Translate
function initGoogleTranslate() {
  // Filtered language codes for Romanian
  const supportedLanguages = ['en', 'ro'];

  // Initialize Google Translate widget
  window.googleTranslateElementInit = () => {
    // eslint-disable-next-line no-new
    new window.google.translate.TranslateElement(
      {
        pageLanguage: 'en', // Default page language
        includedLanguages: supportedLanguages.join(','), // Filtered languages
        autoDisplay: false // Disable auto display to manually render
      },
      'google_translate_element'
    );
  };
}

// Extend the Window interface to include googleTranslateElementInit
declare global {
  interface Window {
    googleTranslateElementInit: () => void;
    google: {
      translate: {
        TranslateElement: any; // Adjust the type as per the documentation
      };
    };
  }
}

const useGoogleTranslate = () => {
  useEffect(() => {
    // Load Google Translate script
    loadGoogleTranslateScript(() => {
      // Initialize Google Translate after script has loaded
      initGoogleTranslate();
    });
    // Disable hover effects on elements containing original text
    const disableHoverEffects = () => {
      const elements = document.querySelectorAll(
        '.goog-te-menu-value, .goog-te-menu-value > span'
      );
      elements.forEach((element: any) => {
        element.addEventListener('mouseenter', () => {
          element.style.textDecoration = 'none'; // Example: Disable text-decoration on hover
          element.style.color = 'inherit'; // Example: Inherit text color
        });
      });
    };

    disableHoverEffects(); // Call the function on component mount

    // Clean up function (optional)
    return () => {
      // Remove event listeners or clean up if needed
    };
  }, []);
};

export default useGoogleTranslate;
