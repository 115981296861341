import { useCallback, useEffect, useMemo, useReducer } from 'react';
import ReactGA from 'react-ga4';
// context
// hooks
import { useJune } from '@hooks/use-june';
// utils
import axiosInstance, { endpoints } from '@utils/axios';
import { isValidToken, jwtDecode, setSession } from '@utils/jwt';
import { enqueueSnackbar } from 'notistack';
import { AuthContext } from './auth-context';
// types
import { ActionMapType, AuthStateType, AuthUserType } from './types';

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
  RESET_PASSWORD = 'RESET_PASSWORD'
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
  [Types.RESET_PASSWORD]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  user: null,
  loading: true
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      user: action.payload.user
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      user: action.payload.user
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null
    };
  }
  if (action.type === Types.RESET_PASSWORD) {
    return {
      ...state,
      user: null
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const analytics: ReturnType<typeof useJune> = useJune('q3ClmW5XJpuu6QHF');

  const initialize = useCallback(async () => {
    try {
      const accessToken = sessionStorage.getItem(STORAGE_KEY);

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const user = jwtDecode(accessToken);

        dispatch({
          type: Types.INITIAL,
          payload: {
            user
          }
        });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            user: null
          }
        });
      }
    } catch (error) {
      enqueueSnackbar(error, { variant: 'error' });
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: null
        }
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(
    async (username: string, password: string) => {
      const data = {
        username,
        password
      };
      const res = await axiosInstance.post(endpoints.AUTH.LOGIN, data);

      const { access_token: accessToken } = res.data;
      const gaUserId = username;
      ReactGA.set({ userId: gaUserId });
      setSession(accessToken);
      const user = jwtDecode(accessToken);
      if (analytics) {
        analytics.identify(user.username.toLowerCase(), {
          email: user.username,
          name: user.name,
          country: user.country_name,
          client: !user.isCustomerSuccessUser ? user.client_name : '-',
          isCustomerSuccessorUser: user.isCustomerSuccessUser ? 'Yes' : 'No'
        });
        analytics.track(
          'Signed In',
          {
            browser: window.navigator.userAgent
          },
          {
            // Add the GROUP_ID here to track this event on behalf of a workspace
            context: {
              groupId: !user.isCustomerSuccessUser
                ? user.client_name
                : 'Customer Service'
            }
          }
        );
      }

      dispatch({
        type: Types.LOGIN,
        payload: {
          user
        }
      });
    },
    [analytics]
  );

  // REGISTER
  const register = useCallback(
    async (
      email: string,
      password: string,
      firstName: string,
      lastName: string
    ) => {
      const data = {
        email,
        password,
        firstName,
        lastName
      };

      const res = await axiosInstance.post(endpoints.AUTH.REGISTER, data);
      const { accessToken, user } = res.data;
      sessionStorage.setItem(STORAGE_KEY, accessToken);

      dispatch({
        type: Types.REGISTER,
        payload: {
          user
        }
      });
    },
    []
  );

  // RESET PASSWORD
  const resetPassword = useCallback(async (password: string, token: string) => {
    const data = {
      password
    };

    await axiosInstance.post(
      endpoints.AUTH.RESET_PASSWORD.replace('{token}', token),
      data
    );

    dispatch({
      type: Types.RESET_PASSWORD
    });
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: Types.LOGOUT
    });
  }, []);

  const Token = sessionStorage.getItem(STORAGE_KEY);
  const checkAuthenticated = isValidToken(Token)
    ? 'authenticated'
    : 'unauthenticated';
  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      login,
      register,
      logout,
      resetPassword
    }),
    [login, logout, register, resetPassword, state.user, status]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}
