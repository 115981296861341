import { memo } from 'react';

function RomiIcon({ color }: any) {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='48'
        height='48'
        viewBox='0 0 48 48'
        fill='none'
      >
        <path
          d='M19.4687 30.541L22.4563 30.5417C25.8781 30.5417 28.6519 33.3959 28.6519 36.9167L19.013 36.9154L19.0143 38.3334L30.0287 38.3322V36.9167C30.0287 35.3832 29.5906 33.9373 28.8087 32.6656L32.7823 32.6667C35.5253 32.6667 37.8935 34.3174 39 36.7062C35.7442 41.127 30.4719 44 24.5215 44C20.7198 44 17.4992 43.1632 14.8834 41.6982L14.8854 28.5173C16.6003 28.7699 18.1694 29.4871 19.4687 30.541ZM12.1304 27C12.8364 27 13.4184 27.5469 13.4979 28.2515L13.5072 28.4154V41.1667C13.5072 41.949 12.8908 42.5833 12.1304 42.5833H9.37679C8.61642 42.5833 8 41.949 8 41.1667V28.4167C8 27.6343 8.61642 27 9.37679 27H12.1304Z'
          fill={color}
        />
        <path
          d='M32.4609 19.0679C34.2358 14.8554 32.6428 9.87928 28.5892 7.53389C25.66 5.83903 22.1861 5.94618 19.4456 7.51216L18.307 5.51516C21.7327 3.55773 26.075 3.42379 29.7365 5.54237C34.8887 8.52345 36.8609 14.9046 34.4605 20.2249L36 21.1156L31.2214 23.6614L31.0321 18.2411L32.4609 19.0679ZM15.5391 11.9321C13.7642 16.1446 15.3573 21.1207 19.4108 23.466C22.34 25.161 25.8138 25.0538 28.5542 23.488L29.6928 25.4849C26.2672 27.4423 21.925 27.5762 18.2635 25.4576C13.1113 22.4765 11.1391 16.0954 13.5395 10.7751L12 9.88437L16.7786 7.33858L16.968 12.7589L15.5391 11.9321ZM19.9844 17.7996H26.2946C26.6115 17.7996 26.8683 17.5422 26.8683 17.2247C26.8683 16.9072 26.6115 16.6498 26.2946 16.6498H21.7054C20.1213 16.6498 18.8371 15.3629 18.8371 13.7752C18.8371 12.1877 20.1213 10.9008 21.7054 10.9008H22.8527V9.75096H25.1473V10.9008H28.0156V13.2003H21.7054C21.3886 13.2003 21.1317 13.4578 21.1317 13.7752C21.1317 14.0928 21.3886 14.3501 21.7054 14.3501H26.2946C27.8787 14.3501 29.1629 15.6371 29.1629 17.2247C29.1629 18.8123 27.8787 20.0992 26.2946 20.0992H25.1473V21.249H22.8527V20.0992H19.9844V17.7996Z'
          fill={color}
        />
      </svg>
    </>
  );
}

export default memo(RomiIcon);
