import { memo } from 'react';

function NetRevenueIcon({ color }: any) {
  return (
    <>
      <svg
        width='48'
        height='48'
        viewBox='0 0 48 48'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M17.3684 36.4H7.89474V21.2H17.3684V36.4ZM28.7368 36.4H19.2632V13.6H28.7368V36.4ZM40.1053 36.4H30.6316V6H40.1053V36.4ZM42 44H6V40.2H42V44Z'
          fill={color}
        />
        <path
          d='M12 18C8.68629 18 6 15.3137 6 12C6 8.68629 8.68629 6 12 6C15.3137 6 18 8.68629 18 12C18 15.3137 15.3137 18 12 18ZM9.9 13.2V14.4H11.4V15.6H12.6V14.4H13.2C14.0284 14.4 14.7 13.7284 14.7 12.9C14.7 12.0716 14.0284 11.4 13.2 11.4H10.8C10.6343 11.4 10.5 11.2657 10.5 11.1C10.5 10.9343 10.6343 10.8 10.8 10.8H14.1V9.60001H12.6V8.4H11.4V9.60001H10.8C9.97157 9.60001 9.3 10.2716 9.3 11.1C9.3 11.9284 9.97157 12.6 10.8 12.6H13.2C13.3657 12.6 13.5 12.7343 13.5 12.9C13.5 13.0657 13.3657 13.2 13.2 13.2H9.9Z'
          fill={color}
        />
      </svg>
    </>
  );
}

export default memo(NetRevenueIcon);
