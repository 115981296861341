import { memo } from 'react';

function DiscountPercentageIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='62'
      height='62'
      viewBox='0 0 62 62'
      fill='none'
    >
      <g clipPath='url(#clip0_1215_20622)'>
        <path
          d='M28.5282 2.92546C26.2565 4.62199 18.3721 11.9566 14.7938 15.7227C11.1271 19.4003 4.01403 27.5257 2.35141 29.8567C-0.422846 33.6494 -0.82851 39.6605 1.68297 43.6662C3.22208 46.0663 5.48532 49.1796 9.15325 52.8621C12.8345 56.53 15.949 58.7933 18.3491 60.3324C22.3561 62.8427 28.366 62.437 32.1586 59.6639C34.4897 58.0001 42.6151 50.8883 46.2927 47.2216C50.0587 43.6432 57.3934 35.7588 59.0899 33.4871C60.8893 31.2457 61.2986 27.1927 61.2986 27.1927C61.5057 24.8059 61.7031 21.632 61.8605 17.4821C62.0385 12.8321 62.0518 9.49722 61.9562 7.10441C61.8181 3.09742 58.9191 0.197221 54.9122 0.0603853C52.5193 -0.0352788 49.1832 -0.0219584 44.5344 0.156049C40.3845 0.313471 37.2107 0.510854 34.8239 0.717924C34.8239 0.717924 30.823 1.09937 28.5295 2.92667L28.5282 2.92546Z'
          fill='#01C3B7'
        />
        <path
          d='M59.437 1.72298C58.2405 0.722749 56.6845 0.119702 54.9093 0.0579439C52.5164 -0.0377202 49.1803 -0.0243999 44.5315 0.153608C40.3816 0.31103 37.2078 0.508413 34.821 0.715483C34.821 0.715483 30.8201 1.09693 28.5266 2.92423C26.2548 4.62076 18.3704 11.9554 14.7921 15.7214C11.3397 19.1835 4.83578 26.586 2.69727 29.3869C6.13754 32.7617 10.3601 35.4476 15.2208 37.1223C32.7128 43.1528 51.7802 33.8625 57.8119 16.3705C59.489 11.5049 59.9782 6.51708 59.4382 1.72177L59.437 1.72298Z'
          fill='#7FE2DC'
        />
        <path
          d='M53.4713 8.16296C52.017 6.88058 49.7961 6.88058 48.3418 8.16296C46.5775 9.7178 46.5775 12.4533 48.3418 14.0069C49.7961 15.2893 52.017 15.2893 53.4713 14.0069C55.2357 12.4521 55.2357 9.71659 53.4713 8.16296Z'
          fill='white'
        />
        <path
          d='M27.5946 18.1604C27.1902 16.2847 24.8482 15.4697 23.5828 16.7339C23.5828 16.7339 22.7509 17.5077 23.004 18.7864C25.0735 28.2342 26.9807 37.8902 29.0441 46.9904C29.3251 48.2304 30.6002 49.0756 31.8923 48.8976C33.1843 48.7184 33.9944 47.5874 33.7038 46.3462C31.5919 37.2242 29.6605 27.5742 27.5946 18.1604Z'
          fill='white'
        />
        <path
          d='M46.1434 26.8064C44.6576 25.2855 42.8787 24.502 40.7971 24.4524C38.7228 24.3967 36.9742 25.0833 35.5767 26.4807C34.1793 27.8781 33.5085 29.6267 33.5496 31.7047C33.5908 33.7826 34.3767 35.5676 35.9025 37.0703C37.4452 38.5901 39.2556 39.3784 41.3045 39.445C43.3534 39.5104 45.0729 38.8613 46.4619 37.4663C47.8726 36.0495 48.5435 34.313 48.4974 32.2556C48.4502 30.1983 47.6789 28.3758 46.1434 26.8052V26.8064ZM43.219 34.1411C42.6741 34.6884 42.0286 34.9258 41.2912 34.8785C40.5464 34.8228 39.8489 34.4741 39.1865 33.8141C38.5653 33.1953 38.2396 32.5087 38.2021 31.7628C38.156 31.0084 38.4055 30.3678 38.9335 29.8386C39.453 29.3179 40.0948 29.0842 40.8383 29.1217C41.583 29.175 42.2587 29.5092 42.8678 30.1244C43.5011 30.7638 43.8474 31.4637 43.9092 32.2193C43.971 32.9761 43.7421 33.6155 43.219 34.1399V34.1411Z'
          fill='white'
        />
        <path
          d='M21.0712 29.4692C19.5927 27.947 17.838 27.1478 15.823 27.0812C13.8153 27.0073 12.1611 27.6734 10.8267 29.0938C9.49951 30.5045 8.85771 32.2895 8.86134 34.4147C8.8674 36.5386 9.55884 38.3635 10.9744 39.8796C12.4045 41.4127 14.1241 42.1816 16.1282 42.2119C18.1323 42.2409 19.8554 41.5422 21.2831 40.1291C22.7266 38.6856 23.435 36.9467 23.4059 34.8978C23.3781 32.8489 22.5982 31.041 21.0712 29.4704V29.4692ZM18.102 36.8426C17.5559 37.3984 16.9177 37.6442 16.1996 37.6043C15.4731 37.5558 14.8034 37.2107 14.1749 36.5459C13.5852 35.9223 13.2837 35.2284 13.2583 34.4716C13.2244 33.7063 13.4714 33.056 13.9836 32.5207C14.497 31.9855 15.1086 31.7421 15.8303 31.7966C16.5532 31.8511 17.2108 32.1877 17.8029 32.8041C18.4193 33.4459 18.7547 34.1446 18.808 34.9039C18.8613 35.6631 18.6251 36.3073 18.102 36.8402V36.8426Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_1215_20622'>
          <rect width='62' height='62' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default memo(DiscountPercentageIcon);
