import { memo } from 'react';

function CloudOfflineIcon({ color }: any) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
    >
      <path
        d='M35.94 18.4219L16.545 37.8169H35.295C40.65 37.8169 45 33.4669 45 28.1119C45 22.9819 40.995 18.7519 35.94 18.4219Z'
        fill={color}
      />
      <path
        d='M44.5605 3.43945C43.9746 2.85352 43.0254 2.85352 42.4395 3.43945L29.0802 16.7987C28.738 15.791 28.2501 14.8486 27.615 14.025C25.845 11.685 23.04 10.185 19.89 10.185C14.97 10.185 10.89 13.86 10.275 18.705C6.04504 19.8 3 23.64 3 28.11C3 31.005 4.27496 33.5999 6.285 35.355C7.04727 36.0488 7.92627 36.5931 8.87595 37.003L3.43945 42.4395C2.85352 43.0254 2.85352 43.9746 3.43945 44.5605C3.73242 44.8535 4.11621 45 4.5 45C4.88379 45 5.26758 44.8535 5.56055 44.5605L44.5605 5.56055C45.1465 4.97461 45.1465 4.02539 44.5605 3.43945Z'
        fill={color}
      />
    </svg>
  );
}

export default memo(CloudOfflineIcon);
