import { memo } from 'react';

function OperatingProfitIcon({ color }: any) {
  return (
    <>
      <svg
        width='48'
        height='48'
        viewBox='0 0 48 48'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M18.6698 23.005L23.0098 23.0058C27.9804 23.0058 32.0098 27.0352 32.0098 32.0058H18.0078L18.0098 34.0058L34.0098 34.0042V32.0058C34.0098 29.841 33.3734 27.7996 32.2376 26.0042L38.0098 26.0058C41.9944 26.0058 45.4346 28.3364 47.042 31.7086C42.3124 37.95 34.6536 42.0058 26.0098 42.0058C20.4872 42.0058 15.8089 40.8246 12.0091 38.7564L12.0118 20.148C14.5029 20.5046 16.7824 21.5172 18.6698 23.005ZM10.0098 38.0058C10.0098 39.1104 9.11435 40.0058 8.00977 40.0058H4.00977C2.90521 40.0058 2.00977 39.1104 2.00977 38.0058V20.0058C2.00977 18.9013 2.90521 18.0059 4.00977 18.0059H8.00977C9.11435 18.0059 10.0098 18.9013 10.0098 20.0058V38.0058ZM36.0098 10.0059C39.3234 10.0059 42.0098 12.6922 42.0098 16.0059C42.0098 19.3196 39.3234 22.0058 36.0098 22.0058C32.696 22.0058 30.0098 19.3196 30.0098 16.0059C30.0098 12.6922 32.696 10.0059 36.0098 10.0059ZM22.0098 4.00586C25.3234 4.00586 28.0098 6.69216 28.0098 10.0059C28.0098 13.3196 25.3234 16.0059 22.0098 16.0059C18.6961 16.0059 16.0098 13.3196 16.0098 10.0059C16.0098 6.69216 18.6961 4.00586 22.0098 4.00586Z'
          fill={color}
        />
      </svg>
    </>
  );
}

export default memo(OperatingProfitIcon);
