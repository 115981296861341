// eslint-disable-next-line import/no-extraneous-dependencies
import posthog from 'posthog-js';

const PH_CONFIG = {
  api_host: import.meta.env.VITE_REACT_APP_PH_API_HOST as string,
  person_profiles: 'identified_only'
};

const initPostHog = () => {
  if (
    !window.location.host.includes('127.0.0.1') &&
    !window.location.host.includes('localhost')
  ) {
    posthog.init(
      import.meta.env.VITE_REACT_APP_PH_API_KEY as string,
      PH_CONFIG as any
    );
  }

  return posthog;
};

export const posthogInstance = initPostHog();
