// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// routes
import Router from '@routes/index';
// Providers
import { AuthConsumer, AuthProvider } from '@context/auth';
import { SettingsProvider } from '@context/settings';
// additional service's configs
import useGoogleTranslate from '@integrations/google-translate';
import useLocalizeDocumentAttributes from '@integrations/i18n/useLocalizeDocumentAttributes';
import ProductFruitsOnboarding from '@integrations/products-fruits';
// components
import MotionLazy from '@atoms/animate/motion-lazy';
import { ProgressBar } from '@atoms/progress-bar';
import { SettingsDrawer } from '@atoms/settings';
import { SnackbarProvider } from '@atoms/snackbar';
// hooks
import { useScrollToTop } from '@hooks/use-scroll-to-top';
// styles
import ThemeProvider from '@theme/index';
import './styles';

// ----------------------------------------------------------------------

export default function App() {
  useGoogleTranslate();
  useScrollToTop();
  useLocalizeDocumentAttributes();

  return (
    <AuthProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SettingsProvider
          defaultSettings={{
            themeMode: 'light', // 'light' | 'dark'
            themeDirection: 'ltr', //  'rtl' | 'ltr'
            themeContrast: 'default', // 'default' | 'bold'
            themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
            themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
            themeStretch: false
          }}
        >
          <ThemeProvider>
            <MotionLazy>
              <SnackbarProvider>
                <SettingsDrawer />
                <ProgressBar />

                <AuthConsumer>
                  <ProductFruitsOnboarding />
                  <Router />
                </AuthConsumer>
              </SnackbarProvider>
            </MotionLazy>
          </ThemeProvider>
        </SettingsProvider>
      </LocalizationProvider>
    </AuthProvider>
  );
}
