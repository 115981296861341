import { forwardRef } from 'react';
// @mui
import Link from '@mui/material/Link';
import Box, { BoxProps } from '@mui/material/Box';
// routes
import RouterLink from '@atoms/routerLink';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const SmallLogo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx }) => {
    // OR using local (public folder)
    // -------------------------------------------------------
    const sLogo = (
      <Box
        component='img'
        src='/logo/3.png'
        sx={{ width: 50, height: 50, cursor: 'pointer', ...sx }}
      />
    );

    if (disabledLink) {
      return sLogo;
    }

    return (
      <Link component={RouterLink} href='/' sx={{ display: 'contents' }}>
        {sLogo}
      </Link>
    );
  }
);

export default SmallLogo;
