import { memo } from 'react';

function ChurnedCustomerIcon() {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='49'
        height='49'
        viewBox='0 0 49 49'
        fill='none'
      >
        <path
          d='M24.333 20.3389C28.7513 20.3389 32.333 16.7571 32.333 12.3389C32.333 7.92059 28.7513 4.33887 24.333 4.33887C19.9147 4.33887 16.333 7.92059 16.333 12.3389C16.333 16.7571 19.9147 20.3389 24.333 20.3389Z'
          fill='#02D2C5'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M33.333 44.3389C30.033 44.3389 28.383 44.3389 27.359 43.3129C26.333 42.2889 26.333 40.6389 26.333 37.3389C26.333 34.0389 26.333 32.3889 27.359 31.3649C28.383 30.3389 30.033 30.3389 33.333 30.3389C36.633 30.3389 38.283 30.3389 39.307 31.3649C40.333 32.3889 40.333 34.0389 40.333 37.3389C40.333 40.6389 40.333 42.2889 39.307 43.3129C38.283 44.3389 36.633 44.3389 33.333 44.3389Z'
          fill='#02D2C5'
        />
        <path
          d='M31.689 27.3449C30.743 27.3549 29.861 27.3909 29.093 27.4929C27.807 27.6669 26.399 28.0789 25.237 29.2429C24.073 30.4049 23.661 31.8129 23.489 33.0989C23.333 34.2549 23.333 35.6669 23.333 37.1669V37.5109C23.333 39.0109 23.333 40.4229 23.489 41.5789C23.609 42.4809 23.849 43.4429 24.383 44.3389H24.333C8.33301 44.3389 8.33301 40.3089 8.33301 35.3389C8.33301 30.3689 15.497 26.3389 24.333 26.3389C26.985 26.3389 29.487 26.7009 31.689 27.3449Z'
          fill='#02D2C5'
        />
        <path
          d='M32.9977 37.6047C33.0181 37.5182 33.0286 37.4292 33.0286 37.3389C33.0286 36.9819 32.8641 36.6451 32.5753 36.4061L32.5751 36.406L32.5256 36.365C32.5256 36.365 32.5255 36.365 32.5255 36.365C31.5866 35.5884 31.0159 35.0994 30.8344 34.0561L30.8344 34.0558C30.8312 34.0376 30.8295 34.0025 30.8891 33.9495C30.9539 33.892 31.0718 33.8389 31.2216 33.8389H33.333H35.4444C35.5942 33.8389 35.7121 33.892 35.7769 33.9495C35.8365 34.0025 35.8348 34.0376 35.8316 34.0558L35.8316 34.0561C35.6502 35.0994 35.0793 35.5884 34.1404 36.365L34.0909 36.406L34.0908 36.4061C33.802 36.6451 33.6374 36.9818 33.6374 37.3389C33.6374 37.4292 33.648 37.5183 33.6684 37.6049C33.4583 37.5031 33.2078 37.5031 32.9977 37.6047Z'
          fill='white'
          stroke='white'
        />
      </svg>
    </>
  );
}

export default memo(ChurnedCustomerIcon);
