import { ProductFruits } from 'react-product-fruits';
// utils
import { getSession, jwtDecode } from '@utils/jwt';

const productFruitsOnboarding = () => {
  const session = getSession();
  const decodedToken = session ? jwtDecode(session || '') : {};

  const userInfo = {
    username: decodedToken.username || '',
    email: decodedToken.username || ''
  };

  const PF_KEY: string =
    import.meta.env.VITE_REACT_APP_PRODUCT_FRUITS_KEY?.toString() || '';
  if (!session) return <></>;

  return <ProductFruits workspaceCode={PF_KEY} language='en' user={userInfo} />;
};

export default productFruitsOnboarding;
