import { memo } from 'react';

function UserSwitchIcon({ color }: any) {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='22'
        height='22'
        viewBox='0 0 22 22'
        fill='none'
      >
        <path
          d='M16.634 13.4771C11.8259 11.4012 6.30524 11.6038 1.63629 14.0849C1.14003 14.3486 0.829834 14.8647 0.829834 15.4267V18.7925C0.829834 19.6785 1.54804 20.3967 2.434 20.3967H14.2313L12.8449 19.0102C12.3861 18.5514 12.2488 17.8615 12.4971 17.2621C12.6315 16.9377 12.8644 16.6744 13.1527 16.5011C12.6866 16.2205 12.375 15.7096 12.375 15.126C12.375 14.24 13.0932 13.5218 13.9791 13.5218H16.6543C16.6473 13.507 16.6405 13.4921 16.634 13.4771Z'
          fill={color}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M9.99716 1.14648C7.33932 1.14648 5.18469 3.30112 5.18469 5.95898C5.18469 8.61686 7.33932 10.7715 9.99716 10.7715C12.655 10.7715 14.8097 8.61686 14.8097 5.95898C14.8097 3.30112 12.655 1.14648 9.99716 1.14648Z'
          fill={color}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M17.618 12.3479C17.8865 12.0794 18.3217 12.0794 18.5902 12.3479L20.8819 14.6395C21.0785 14.8361 21.1374 15.1319 21.031 15.3887C20.9245 15.6457 20.6738 15.8131 20.3958 15.8131H13.9791C13.5994 15.8131 13.2916 15.5053 13.2916 15.1256C13.2916 14.7459 13.5994 14.4381 13.9791 14.4381H18.736L17.618 13.3201C17.3495 13.0516 17.3495 12.6163 17.618 12.3479Z'
          fill={color}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M16.7569 20.6528C16.4884 20.9213 16.0532 20.9213 15.7847 20.6528L13.493 18.3611C13.2964 18.1645 13.2376 17.8688 13.344 17.6119C13.4504 17.355 13.7011 17.1875 13.9791 17.1875H20.3958C20.7755 17.1875 21.0833 17.4953 21.0833 17.875C21.0833 18.2547 20.7755 18.5625 20.3958 18.5625H15.6389L16.7569 19.6806C17.0254 19.949 17.0254 20.3843 16.7569 20.6528Z'
          fill={color}
        />
      </svg>
    </>
  );
}

export default memo(UserSwitchIcon);
