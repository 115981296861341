import { memo } from 'react';

function OrderRunrateIcon({ color }: any) {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='48'
        height='48'
        viewBox='0 0 48 48'
        fill='none'
      >
        <path
          d='M12.0078 18H39.8847L40.8847 14H16.0078V10H43.4463C44.5509 10 45.4463 10.8954 45.4463 12C45.4463 12.1635 45.4263 12.3264 45.3865 12.4851L40.3865 32.485C40.1641 33.3754 39.3641 34 38.4463 34H10.0078C8.90325 34 8.00781 33.1046 8.00781 32V8H4.00781V4H10.0078C11.1124 4 12.0078 4.89544 12.0078 6V18ZM12.0078 46C9.79867 46 8.00781 44.2092 8.00781 42C8.00781 39.7908 9.79867 38 12.0078 38C14.217 38 16.0078 39.7908 16.0078 42C16.0078 44.2092 14.217 46 12.0078 46ZM36.0079 46C33.7987 46 32.0079 44.2092 32.0079 42C32.0079 39.7908 33.7987 38 36.0079 38C38.2171 38 40.0079 39.7908 40.0079 42C40.0079 44.2092 38.2171 46 36.0079 46Z'
          fill={color}
        />
        <path
          d='M23.1991 29.9445C23.2279 29.5546 23.3939 29.2028 23.6575 28.9445C22.3989 28.4642 20.5725 27.8135 18.1016 26.9648C19.605 27.8645 21.6838 29.1005 23.1991 29.9445Z'
          fill='white'
        />
        <path
          d='M19.8568 28.7351C19.1219 28.3014 18.4056 27.8743 17.7874 27.5043C17.505 27.333 17.4015 26.995 17.5394 26.6995C17.6767 26.4058 18 26.2695 18.3086 26.3743C19.1032 26.6472 19.8293 26.899 20.4944 27.1322C20.6463 26.8849 20.818 26.6541 21.0065 26.4402L18.3515 23.7852C16.894 25.3496 16 27.4452 16 29.747H19.7523C19.7523 29.4049 19.7883 29.0659 19.8568 28.7351Z'
          fill='white'
        />
        <path
          d='M24.7584 31.0012C25.2765 31.0012 25.6965 30.5812 25.6965 30.0631C25.6965 29.545 25.2765 29.125 24.7584 29.125C24.2403 29.125 23.8203 29.545 23.8203 30.0631C23.8203 30.5812 24.2403 31.0012 24.7584 31.0012Z'
          fill='white'
        />
        <path
          d='M28.4922 26.4424C29.2714 27.3249 29.7486 28.4799 29.7486 29.747H33.5009C33.5009 27.4452 32.6069 25.3496 31.1495 23.7852L28.4922 26.4424Z'
          fill='white'
        />
        <path
          d='M24.4382 21C22.26 21.0769 20.2822 21.9525 18.7891 23.3436L21.4435 25.9981C22.2672 25.2708 23.3166 24.8252 24.4382 24.7551V21Z'
          fill='white'
        />
        <path
          d='M25.0703 24.7601C26.2141 24.8314 27.2532 25.2864 28.0622 26.0009L30.7194 23.3436C29.2263 21.9528 27.2489 21.0772 25.0703 21V24.7601Z'
          fill='white'
        />
      </svg>
    </>
  );
}

export default memo(OrderRunrateIcon);
