import { memo } from 'react';

function BidIcon() {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='14'
        height='14'
        viewBox='0 0 14 14'
        fill='none'
      >
        <path d='M0 12.8438H8.23567V14.0002H0V12.8438Z' fill='#00A76F' />
        <path
          d='M7.05914 11.6778C7.05914 11.5245 6.99716 11.3774 6.88684 11.269C6.77652 11.1605 6.62689 11.0996 6.47087 11.0996H1.76478C1.60876 11.0996 1.45913 11.1605 1.34881 11.269C1.23849 11.3774 1.17651 11.5245 1.17651 11.6778V12.2655H7.05914V11.6778Z'
          fill='#00A76F'
        />
        <path
          d='M8.00426 6.94973C7.73016 7.23687 7.50031 7.56192 7.32202 7.91452L12.164 12.674C12.2743 12.7824 12.4239 12.8433 12.5799 12.8433C12.7359 12.8433 12.8855 12.7824 12.9958 12.674L13.8278 11.8562C13.8824 11.8025 13.9257 11.7388 13.9553 11.6686C13.9849 11.5985 14.0001 11.5233 14.0001 11.4473C14.0001 11.3714 13.9849 11.2962 13.9553 11.2261C13.9257 11.1559 13.8824 11.0921 13.8278 11.0385L8.98577 6.28027C8.62643 6.45399 8.29558 6.67965 8.00426 6.94973Z'
          fill='#00A76F'
        />
        <path
          d='M5.09243 4.08636C4.64758 4.49217 4.13262 4.81668 3.57178 5.04465L6.61221 8.03327C6.84621 7.48294 7.17668 6.97726 7.58858 6.53925C8.03348 6.13347 8.54842 5.80891 9.10924 5.58082L6.06866 2.59277C5.83458 3.1429 5.50417 3.64842 5.09243 4.08636Z'
          fill='#00A76F'
        />
        <path
          d='M7.17129 0.409299L6.33949 1.22693C6.10979 1.45271 6.10979 1.81877 6.33949 2.04456L9.6667 5.31507C9.89639 5.54086 10.2688 5.54086 10.4985 5.31507L11.3303 4.49744C11.56 4.27166 11.56 3.9056 11.3303 3.67981L8.00309 0.409299C7.77339 0.183516 7.40098 0.183517 7.17129 0.409299Z'
          fill='#00A76F'
        />
        <path
          d='M2.17971 5.3136L1.34791 6.13122C1.11821 6.35701 1.11821 6.72307 1.34791 6.94885L4.67512 10.2194C4.90482 10.4452 5.27723 10.4452 5.50692 10.2194L6.33872 9.40174C6.56842 9.17596 6.56842 8.80989 6.33872 8.58411L3.01151 5.3136C2.78182 5.08781 2.40941 5.08781 2.17971 5.3136Z'
          fill='#00A76F'
        />
      </svg>
    </>
  );
}

export default memo(BidIcon);
