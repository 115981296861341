import { memo } from 'react';

function FreeDeliveryIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='62'
      height='62'
      viewBox='0 0 62 62'
      fill='none'
    >
      <g clipPath='url(#clip0_1459_31129)'>
        <path
          d='M25.5632 46.7427C27.5407 44.8977 27.8441 42.009 26.2409 40.2906C24.6377 38.5722 21.7349 38.6748 19.7574 40.5198C17.7799 42.3647 17.4764 45.2534 19.0797 46.9718C20.6829 48.6902 23.5856 48.5876 25.5632 46.7427Z'
          fill='#454565'
        />
        <path
          d='M51.7331 46.7417C53.7106 44.8967 54.014 42.008 52.4108 40.2896C50.8076 38.5712 47.9048 38.6738 45.9273 40.5188C43.9498 42.3637 43.6463 45.2524 45.2496 46.9708C46.8528 48.6892 49.7556 48.5867 51.7331 46.7417Z'
          fill='#454565'
        />
        <path
          d='M4.80527 24.1927C4.80527 23.0507 3.87954 22.125 2.7376 22.125C1.59565 22.125 0.669922 23.0507 0.669922 24.1927C0.669922 25.3346 1.59565 26.2604 2.7376 26.2604C3.87954 26.2604 4.80527 25.3346 4.80527 24.1927Z'
          fill='#ED912E'
        />
        <path
          d='M41.971 43.2225C42.706 39.5897 46.1378 36.7028 49.8263 36.7028C53.5148 36.7028 55.9391 39.3802 55.8689 42.8229C59.71 42.9234 60.7793 37.8653 60.7793 37.8653C61.1026 36.4509 61.5857 33.6936 61.9902 30.9872C62.1198 30.1202 62.018 29.2689 61.6959 28.5157C61.0735 27.0601 59.8529 24.4602 58.0704 21.7974C56.7408 19.8114 54.5042 18.6332 51.9539 18.592C50.433 18.5666 48.9387 18.5533 47.8125 18.5533L47.8028 18.5436C47.6587 16.1108 45.8847 14.2496 43.3962 14.0268C41.7772 13.8815 35.3641 13.7773 32.2859 13.7773C31.0628 13.7773 29.307 13.7943 27.5318 13.8234V13.8161H27.5196L27.5136 13.8137L27.5087 13.8161H6.17203C5.03012 13.8161 4.10375 14.7425 4.10375 15.8844V15.8953C4.10375 17.0311 5.02527 17.9527 6.16113 17.9527L10.4636 17.9587C11.5304 18.035 12.3708 18.9238 12.3708 20.01V20.0209C12.3708 21.1629 11.4445 22.0892 10.3025 22.0892H8.09863C6.95672 22.0892 6.03035 23.0156 6.03035 24.1575C6.03035 25.2994 6.95672 26.2258 8.09863 26.2258H10.3025C11.4445 26.2258 12.3708 27.1521 12.3708 28.2941C12.3708 29.436 11.4445 30.3623 10.3025 30.3623H2.05363C0.911719 30.3623 -0.0146484 31.2887 -0.0146484 32.4306C-0.0146484 33.5725 0.911719 34.4989 2.05363 34.4989H10.305C11.4469 34.4989 12.3732 35.4253 12.3732 36.5672C12.3732 37.7091 11.4469 38.6355 10.305 38.6355H7.75473C6.61281 38.6355 5.68644 39.5618 5.68644 40.7038C5.68644 41.8457 6.61281 42.772 7.75473 42.772L15.8983 42.766L16.5764 41.0791C17.9629 38.5204 20.7214 36.7089 23.6422 36.7089C27.3319 36.7089 29.9536 39.5982 29.6642 43.2334H41.9685M47.6164 21.4232C48.6965 21.4244 50.0976 21.4389 51.5023 21.462C53.2048 21.4898 54.6943 22.2709 55.588 23.6065C56.6693 25.2231 57.5339 26.8228 58.16 28.1027C58.5584 28.9165 57.8246 29.9761 56.8619 29.9761H46.5386L47.6164 21.4232Z'
          fill='#FAAB48'
        />
        <path
          d='M43.3953 14.0258C41.7762 13.8805 35.3631 13.7764 32.2849 13.7764C31.0619 13.7764 29.306 13.7933 27.5308 13.8224V13.8151H27.5187L27.5126 13.8127L27.5078 13.8151H6.17227C5.03035 13.8151 4.10398 14.7415 4.10398 15.8834V15.8943C4.10398 17.0302 5.02551 17.9517 6.16137 17.9517L10.4638 17.9577C11.5307 18.034 12.3711 18.9229 12.3711 20.0091V20.02C12.3711 21.1619 11.4447 22.0882 10.3028 22.0882H8.09887C6.95695 22.0882 6.03059 23.0146 6.03059 24.1565C6.03059 25.2984 6.95695 26.2248 8.09887 26.2248H10.3028C11.4447 26.2248 12.3711 27.1512 12.3711 28.2931C12.3711 29.435 11.4447 30.3614 10.3028 30.3614H2.05387C1.02457 30.3614 0.173281 31.1146 0.0146484 32.1003V32.759C0.173281 33.7435 1.02457 34.4979 2.05387 34.4979H10.3052C11.4471 34.4979 12.3735 35.4243 12.3735 36.5662C12.3735 37.0579 12.2015 37.5083 11.9145 37.8631C12.8591 37.943 13.8133 37.9842 14.7784 37.9842C16.6336 37.9842 18.4536 37.8304 20.2264 37.541C21.2836 37.0082 22.4497 36.7055 23.6425 36.7055C23.7454 36.7055 23.8471 36.7091 23.9488 36.7127C34.4779 33.7217 42.8939 25.7102 46.4468 15.4293C45.6767 14.6482 44.6232 14.1384 43.3941 14.0282L43.3953 14.0258Z'
          fill='#F8C358'
        />
        <path
          d='M35.7635 21.4453H31.817C31.3472 21.4453 30.9125 21.8268 30.8459 22.2966L29.8904 29.0924C29.8238 29.5622 30.152 29.9437 30.6218 29.9437H34.5683C34.9824 29.9437 35.3651 29.6082 35.4232 29.1941C35.4813 28.78 35.1931 28.4445 34.779 28.4445H31.5978C31.5918 28.4445 31.5869 28.4385 31.5869 28.4324L31.8909 26.2697C31.8909 26.2697 31.8957 26.2612 31.9018 26.2612H34.3769C34.7911 26.2612 35.1737 25.9258 35.2319 25.5116C35.29 25.0975 35.0018 24.7621 34.5876 24.7621H32.1149C32.1089 24.7621 32.104 24.756 32.104 24.75L32.3571 22.9529C32.3571 22.9529 32.3619 22.9445 32.368 22.9445H35.5516C35.9657 22.9445 36.3484 22.609 36.4065 22.1949C36.4646 21.7807 36.1764 21.4453 35.7623 21.4453H35.7635Z'
          fill='white'
        />
        <path
          d='M21.6842 21.4463H18.1446C17.6748 21.4463 17.24 21.8277 17.1734 22.2976L16.212 29.1418C16.1502 29.585 16.459 29.9446 16.9022 29.9446C17.3454 29.9446 17.7547 29.585 17.8177 29.1418L18.1882 26.5068C18.1882 26.5068 18.1931 26.4983 18.1991 26.4983H20.2674C20.7106 26.4983 21.1199 26.1387 21.1829 25.6955C21.2446 25.2523 20.9358 24.8926 20.4926 24.8926H18.4268C18.4207 24.8926 18.4159 24.8866 18.4159 24.8805L18.6714 23.0605C18.6714 23.0605 18.6762 23.052 18.6823 23.052H21.459C21.9022 23.052 22.3127 22.6923 22.3744 22.2491C22.4362 21.8059 22.1274 21.4463 21.6842 21.4463Z'
          fill='white'
        />
        <path
          d='M28.8759 21.9943C28.1966 21.4106 26.9966 21.4457 26.9966 21.4457H24.2599C23.79 21.4457 23.3553 21.8272 23.2887 22.297L22.3272 29.1412C22.2654 29.5844 22.5742 29.9441 23.0174 29.9441C23.4606 29.9441 23.8699 29.5844 23.9329 29.1412L24.2235 27.0729C24.2235 27.0729 24.2284 27.0645 24.2344 27.0645H26.0084C26.0084 27.0645 26.0169 27.0669 26.0181 27.0717L26.8513 29.4924C26.9469 29.7697 27.2024 29.9453 27.51 29.9453H27.5403C28.1336 29.9453 28.6095 29.3241 28.4243 28.7913L27.7352 26.8065C27.7352 26.8065 27.7352 26.7956 27.7413 26.7932C28.1324 26.6285 28.4582 26.3924 28.7185 26.0824C29.1436 25.5762 29.4063 24.9683 29.5068 24.2563C29.5734 23.7804 29.5577 23.3481 29.4584 22.9606C29.3591 22.5731 29.1654 22.251 28.8772 21.9955L28.8759 21.9943ZM27.8878 24.2551C27.8878 24.2551 27.7268 25.5653 26.3463 25.5653H24.4342L24.8024 22.9449H26.7144C26.7144 22.9449 28.1191 22.8552 27.8878 24.2551Z'
          fill='white'
        />
        <path
          d='M42.3767 21.4453H38.4303C37.9605 21.4453 37.5257 21.8268 37.4591 22.2966L36.5037 29.0924C36.4371 29.5622 36.7653 29.9437 37.2351 29.9437H41.1816C41.5957 29.9437 41.9784 29.6082 42.0365 29.1941C42.0946 28.78 41.8064 28.4445 41.3923 28.4445H38.2111C38.2051 28.4445 38.2002 28.4385 38.2002 28.4324L38.5042 26.2697C38.5042 26.2697 38.509 26.2612 38.5151 26.2612H40.9902C41.4044 26.2612 41.787 25.9258 41.8451 25.5116C41.9033 25.0975 41.6151 24.7621 41.2009 24.7621H38.7282C38.7221 24.7621 38.7173 24.756 38.7173 24.75L38.9704 22.9529C38.9704 22.9529 38.9752 22.9445 38.9813 22.9445H42.1648C42.579 22.9445 42.9616 22.609 43.0198 22.1949C43.0779 21.7807 42.7897 21.4453 42.3755 21.4453H42.3767Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_1459_31129'>
          <rect width='62' height='62' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default memo(FreeDeliveryIcon);
