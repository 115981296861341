import { lazy, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
// auth
import AuthGuard from '@hocs/auth-guard';
// components
import { DashboardLayout } from '@atoms/layouts';
import { LoadingScreen } from '@atoms/loading-screen';
// routes
import { paths } from '@routes/paths';
import { RequiredPerm } from '@routes/permissions';
// hocs
import { CheckPermission } from '@hocs/check-permission';

// ----------------------------------------------------------------------

// ROUTE SCHEME
//  |-Pulse (*Dashboard) page
//  |--Overview tab (*initial)
//  |--|--Root page
//  |--|--Overview details page
//  |--Economics tab

// ----------------------------------------------------------------------

// PAGES (*all under tab "PULES")
const OverviewAppPage = lazy(() => import('@pages/dashboard/OverviewAppPage'));
const OverviewDetailPage = lazy(
  () => import('@pages/dashboard/OverviewDetailPage')
);
const EconomicsPage = lazy(() => import('@pages/dashboard/EconomicsPage'));
// ----------------------------------------------------------------------

// NEW DASHBOARD ROUTES
export const dashboardRoutes = [
  {
    path: paths?.dashboard?.root,
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        element: (
          <CheckPermission permissionRequired={RequiredPerm?.Dashboard}>
            <OverviewAppPage />
          </CheckPermission>
        ),
        index: true
      },
      {
        path: paths?.dashboard?.dashboard,
        element: (
          <CheckPermission permissionRequired={RequiredPerm?.Dashboard}>
            <OverviewAppPage />
          </CheckPermission>
        ),
        index: true
      },
      {
        path: paths?.dashboard?.overview_details,
        element: (
          <CheckPermission permissionRequired={RequiredPerm?.Dashboard}>
            <OverviewDetailPage />
          </CheckPermission>
        )
      },
      {
        path: paths?.dashboard?.finance,
        element: (
          <CheckPermission permissionRequired={RequiredPerm?.Finance}>
            <EconomicsPage />
          </CheckPermission>
        )
      }
    ]
  }
];

// OLD DASHBOARD ROUTES
export const oldDashboardRoutes = [
  {
    path: paths?.dashboard?.root_old,
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        element: (
          <CheckPermission permissionRequired={RequiredPerm?.Dashboard}>
            <OverviewAppPage />
          </CheckPermission>
        ),
        index: true
      },
      {
        path: paths?.dashboard?.dashboard_old,
        element: (
          <CheckPermission permissionRequired={RequiredPerm?.Dashboard}>
            <Navigate replace to={paths?.dashboard?.dashboard} />
          </CheckPermission>
        ),
        index: true
      },
      {
        path: paths?.dashboard?.finance_old,
        element: (
          <CheckPermission permissionRequired={RequiredPerm?.Finance}>
            <Navigate replace to={paths?.dashboard?.finance} />
          </CheckPermission>
        )
      }
    ]
  }
];
