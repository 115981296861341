import { memo } from 'react';

function FreeItemsIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='62'
      height='62'
      viewBox='0 0 62 62'
      fill='none'
    >
      <g clipPath='url(#clip0_1459_31206)'>
        <path
          d='M30.9994 17.8305C30.2777 17.8245 29.5562 17.8569 28.8379 17.9273C16.7588 19.0983 5.99476 29.1842 5.4559 41.0236C5.4559 41.0236 5.37234 45.5864 5.69808 48.1838H56.2693C56.7016 45.5718 56.5114 41.0236 56.5114 41.0236C55.9726 29.1842 45.2073 19.0971 33.1294 17.9225C32.4217 17.8527 31.7106 17.822 30.9994 17.8305Z'
          fill='#33EA06'
        />
        <path
          d='M19.4869 48.1833C32.835 46.2458 43.6451 36.4736 47.1108 23.6824C43.1401 20.5243 38.2528 18.4233 33.1293 17.922C32.4216 17.8506 31.7105 17.8183 30.9993 17.8251C30.2775 17.8192 29.556 17.8515 28.8377 17.922C16.7586 19.0978 5.99461 29.1837 5.45574 41.023C5.45574 41.023 5.38914 44.6946 5.60953 47.3199C6.95352 47.6918 8.31924 47.9802 9.69886 48.1833H19.4869Z'
          fill='#9AFA82'
        />
        <path
          d='M58.7301 45.4816C40.2432 46.3874 21.7594 46.3874 3.27889 45.4816C1.32201 45.3908 -0.11174 46.8318 0.143768 48.7148C0.188976 49.066 0.235799 49.4163 0.284237 49.7659C0.549432 51.6489 2.33435 53.3176 4.21494 53.4702L4.59881 53.4992C4.65569 53.5032 4.70935 53.5271 4.75033 53.5668C4.79131 53.6064 4.81698 53.6593 4.82283 53.716C4.86037 54.0078 5.4731 57.7448 5.51428 58.0366C5.73467 59.6108 7.22291 60.9949 8.78381 61.1221C23.6417 62.2906 38.5684 62.2906 53.4262 61.1221C54.9932 60.9949 56.4814 59.6048 56.6958 58.0366C56.7369 57.7448 57.3497 54.0078 57.3872 53.716C57.3878 53.7099 57.3878 53.7039 57.3872 53.6978C57.3931 53.6411 57.4187 53.5883 57.4597 53.5486C57.5007 53.509 57.5543 53.485 57.6112 53.4811L57.7795 53.4689C59.6601 53.3164 61.4463 51.6525 61.7115 49.7647C61.7599 49.4147 61.8059 49.0648 61.8519 48.7136C62.1208 46.8318 60.6858 45.3908 58.7301 45.4816Z'
          fill='#34344F'
        />
        <path
          d='M12.2536 19.6294C11.9701 19.6296 11.6929 19.5457 11.4571 19.3883C11.2213 19.231 11.0375 19.0071 10.929 18.7452C10.8205 18.4833 10.7921 18.1951 10.8475 17.9171C10.9028 17.639 11.0395 17.3836 11.2401 17.1833C11.7705 16.6521 12.0724 15.9346 12.0812 15.184C12.0901 14.4333 11.8051 13.7089 11.2873 13.1654L11.2376 13.117C10.1624 12.0414 9.55748 10.5832 9.55567 9.06233C9.55385 7.54142 10.1552 6.08186 11.2279 5.00369C11.4871 4.73251 11.8423 4.5737 12.2172 4.56133C12.5921 4.54896 12.9569 4.68402 13.2334 4.93753C13.5099 5.19104 13.676 5.54282 13.6961 5.91739C13.7163 6.29197 13.5888 6.65952 13.341 6.94119L13.2647 7.02353C12.7344 7.5545 12.4326 8.27165 12.4235 9.02202C12.4145 9.77239 12.699 10.4966 13.2163 11.0402L13.266 11.0899C14.3412 12.1673 14.9451 13.6273 14.9451 15.1495C14.9451 16.6717 14.3412 18.1317 13.266 19.2092C13.1333 19.3426 12.9756 19.4485 12.8018 19.5206C12.6281 19.5927 12.4417 19.6297 12.2536 19.6294Z'
          fill='#9AFA82'
        />
        <path
          d='M55.3751 22.5806C55.0916 22.5808 54.8144 22.4969 54.5786 22.3395C54.3428 22.1821 54.159 21.9583 54.0505 21.6964C53.942 21.4345 53.9136 21.1463 53.969 20.8682C54.0243 20.5902 54.161 20.3348 54.3615 20.1345C54.8918 19.6035 55.1937 18.8864 55.2027 18.136C55.2118 17.3856 54.9273 16.6614 54.41 16.1178L54.3603 16.0681C53.2843 14.993 52.6788 13.5349 52.6768 12.0138C52.6747 10.4927 53.2763 9.03288 54.3494 7.95486C54.6086 7.68368 54.9637 7.52487 55.3387 7.5125C55.7136 7.50013 56.0784 7.63519 56.3549 7.8887C56.6314 8.14221 56.7975 8.49399 56.8176 8.86857C56.8377 9.24314 56.7103 9.61069 56.4625 9.89236L56.3874 9.97349C55.8563 10.504 55.5538 11.2213 55.5448 11.972C55.5357 12.7226 55.8208 13.447 56.339 13.9902L56.3887 14.0398C57.464 15.1175 58.0679 16.5777 58.0679 18.1001C58.0679 19.6225 57.464 21.0827 56.3887 22.1604C56.2556 22.2936 56.0976 22.3992 55.9237 22.4713C55.7498 22.5435 55.5634 22.5806 55.3751 22.5806Z'
          fill='#9AFA82'
        />
        <path
          d='M42.1157 15.0535C41.8322 15.0538 41.555 14.9699 41.3192 14.8125C41.0834 14.6551 40.8996 14.4313 40.7911 14.1694C40.6826 13.9075 40.6542 13.6192 40.7096 13.3412C40.7649 13.0632 40.9016 12.8078 41.1022 12.6074C41.6334 12.0767 41.9359 11.3592 41.9449 10.6084C41.954 9.85752 41.6689 9.13293 41.1506 8.58955L41.1009 8.54112C40.0251 7.46582 39.4198 6.00759 39.418 4.48648C39.4162 2.96538 40.0179 1.5057 41.0912 0.427836C41.3516 0.161862 41.7052 0.00757233 42.0773 -0.00241309C42.4494 -0.0123985 42.8108 0.122703 43.085 0.374329C43.3593 0.625954 43.525 0.974378 43.547 1.34593C43.5691 1.71749 43.4457 2.08306 43.2031 2.36534L43.128 2.44647C42.5969 2.97698 42.2945 3.69429 42.2854 4.44494C42.2763 5.19559 42.5614 5.91998 43.0796 6.46315L43.1293 6.5128C44.2046 7.59046 44.8085 9.05068 44.8085 10.5731C44.8085 12.0955 44.2046 13.5557 43.1293 14.6333C42.9964 14.7668 42.8384 14.8726 42.6645 14.9447C42.4905 15.0168 42.304 15.0538 42.1157 15.0535Z'
          fill='#9AFA82'
        />
        <path
          d='M33.1458 17.9368V15.2013C33.8386 14.7405 34.3647 14.069 34.6463 13.286C34.9279 12.5031 34.95 11.6503 34.7094 10.8538C34.4687 10.0573 33.9781 9.35946 33.31 8.86344C32.642 8.36743 31.832 8.09961 31 8.09961C30.168 8.09961 29.358 8.36743 28.69 8.86344C28.0219 9.35946 27.5313 10.0573 27.2906 10.8538C27.05 11.6503 27.0721 12.5031 27.3537 13.286C27.6353 14.069 28.1614 14.7405 28.8542 15.2013V17.9368C28.8542 17.9368 31 18.2468 33.1458 17.9368Z'
          fill='#34344F'
        />
        <path
          d='M19.4106 28.0129C17.5842 29.0422 16.1434 30.5667 15.405 32.3085C15.3176 32.5149 15.1599 32.6923 14.9534 32.8161C14.747 32.9399 14.5021 33.0041 14.2525 32.9998C14.1019 32.9978 13.953 32.9721 13.8127 32.9238C13.6604 32.8757 13.5203 32.8016 13.4005 32.7057C13.2807 32.6097 13.1836 32.4939 13.1146 32.3648C13.0456 32.2357 13.0061 32.0958 12.9985 31.9532C12.9908 31.8106 13.0151 31.668 13.0699 31.5337C14.0003 29.3711 15.8172 27.466 18.1041 26.1777C18.2396 26.0991 18.3914 26.045 18.5508 26.0186C18.7101 25.9922 18.8738 25.9939 19.0324 26.0237C19.191 26.0536 19.3413 26.1108 19.4746 26.1923C19.6079 26.2737 19.7216 26.3777 19.8091 26.4982C19.9835 26.7458 20.0395 27.0443 19.9647 27.3283C19.89 27.6123 19.6907 27.8585 19.4106 28.0129Z'
          fill='white'
        />
        <path
          d='M51.0665 56.7689C46.7555 56.9808 42.3852 57.1261 38.0767 57.2024H38.0465C37.5968 57.2064 37.164 57.0317 36.8433 56.7166C36.5225 56.4015 36.34 55.9719 36.336 55.5222C36.332 55.0726 36.5068 54.6398 36.8218 54.319C37.1369 53.9983 37.5666 53.8158 38.0162 53.8118C42.2896 53.7367 46.6247 53.5926 50.9006 53.3819C51.3502 53.3599 51.7901 53.5174 52.1236 53.8198C52.4571 54.1222 52.6568 54.5447 52.6788 54.9943C52.7008 55.4439 52.5433 55.8839 52.2409 56.2173C51.9385 56.5508 51.5161 56.7505 51.0665 56.7725V56.7689Z'
          fill='#444466'
        />
        <path
          d='M46.5594 35H42.6129C42.1431 35 41.7084 35.3814 41.6417 35.8513L40.6863 42.6471C40.6197 43.1169 40.9479 43.4984 41.4177 43.4984H45.3642C45.7783 43.4984 46.161 43.1629 46.2191 42.7488C46.2772 42.3346 45.989 41.9992 45.5749 41.9992H42.3937C42.3877 41.9992 42.3828 41.9932 42.3828 41.9871L42.6868 39.8244C42.6868 39.8244 42.6916 39.8159 42.6977 39.8159H45.1728C45.587 39.8159 45.9696 39.4805 46.0278 39.0663C46.0859 38.6522 45.7977 38.3168 45.3835 38.3168H42.9108C42.9048 38.3168 42.8999 38.3107 42.8999 38.3046L43.153 36.5076C43.153 36.5076 43.1578 36.4991 43.1639 36.4991H46.3475C46.7616 36.4991 47.1443 36.1637 47.2024 35.7496C47.2605 35.3354 46.9723 35 46.5582 35H46.5594Z'
          fill='white'
        />
        <path
          d='M32.4801 35.001H28.9405C28.4707 35.001 28.0359 35.3824 27.9693 35.8523L27.0079 42.6965C26.9461 43.1397 27.2549 43.4993 27.6981 43.4993C28.1413 43.4993 28.5506 43.1397 28.6136 42.6965L28.9841 40.0615C28.9841 40.0615 28.989 40.053 28.995 40.053H31.0633C31.5065 40.053 31.9158 39.6934 31.9788 39.2502C32.0405 38.807 31.7317 38.4473 31.2885 38.4473H29.2227C29.2166 38.4473 29.2118 38.4412 29.2118 38.4352L29.4673 36.6152C29.4673 36.6152 29.4721 36.6067 29.4782 36.6067H32.2549C32.6981 36.6067 33.1086 36.247 33.1703 35.8038C33.2321 35.3606 32.9233 35.001 32.4801 35.001Z'
          fill='white'
        />
        <path
          d='M39.6718 35.549C38.9925 34.9653 37.7925 35.0004 37.7925 35.0004H35.0558C34.5859 35.0004 34.1512 35.3819 34.0846 35.8517L33.1231 42.6959C33.0613 43.1391 33.3701 43.4988 33.8133 43.4988C34.2565 43.4988 34.6658 43.1391 34.7288 42.6959L35.0194 40.6276C35.0194 40.6276 35.0243 40.6192 35.0303 40.6192H36.8043C36.8043 40.6192 36.8128 40.6216 36.814 40.6264L37.6472 43.0471C37.7428 43.3244 37.9983 43.5 38.3059 43.5H38.3362C38.9295 43.5 39.4054 42.8788 39.2202 42.346L38.5311 40.3612C38.5311 40.3612 38.5311 40.3503 38.5372 40.3479C38.9283 40.1832 39.2541 39.9471 39.5144 39.6371C39.9395 39.1309 40.2022 38.523 40.3027 37.811C40.3693 37.3351 40.3536 36.9028 40.2543 36.5153C40.155 36.1278 39.9613 35.8057 39.6731 35.5502L39.6718 35.549ZM38.6837 37.8098C38.6837 37.8098 38.5227 39.12 37.1422 39.12H35.2301L35.5983 36.4995H37.5103C37.5103 36.4995 38.915 36.4099 38.6837 37.8098Z'
          fill='white'
        />
        <path
          d='M53.1726 35H49.2262C48.7564 35 48.3216 35.3814 48.255 35.8513L47.2996 42.6471C47.233 43.1169 47.5612 43.4984 48.031 43.4984H51.9775C52.3916 43.4984 52.7742 43.1629 52.8324 42.7488C52.8905 42.3346 52.6023 41.9992 52.1882 41.9992H49.007C49.001 41.9992 48.9961 41.9932 48.9961 41.9871L49.3001 39.8244C49.3001 39.8244 49.3049 39.8159 49.311 39.8159H51.7861C52.2003 39.8159 52.5829 39.4805 52.641 39.0663C52.6992 38.6522 52.411 38.3168 51.9968 38.3168H49.5241C49.518 38.3168 49.5132 38.3107 49.5132 38.3046L49.7663 36.5076C49.7663 36.5076 49.7711 36.4991 49.7772 36.4991H52.9607C53.3749 36.4991 53.7575 36.1637 53.8157 35.7496C53.8738 35.3354 53.5856 35 53.1714 35H53.1726Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_1459_31206'>
          <rect width='62' height='62' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default memo(FreeItemsIcon);
