import { getClientCountry } from '@utils/jwt';
import { useMemo } from 'react';
// components
import { Label } from '@atoms/label';
// routes
import { paths } from '@routes/paths';
import { RequiredPerm } from '@routes/permissions';
// styles
import ICONS from '@theme/icons';

// ----------------------------------------------------------------------

const ROMANIA_MODULES = [
  'home',
  'finance',
  'ads',
  'overview',
  'economics',
  'discount',
  'profile',
  'summary',
  'calendar',
  'budget',
  'growdash academy',
  'analyze my menu',
  'menu suggestion',
  'competitors',
  'popular brands',
  'popular items',
  'item search'
];

// ----------------------------------------------------------------------

export function useNavData() {
  const userCountry = getClientCountry();
  const data = useMemo(() => {
    const navItems = [
      // DASHBOARD
      // ----------------------------------------------------------------------
      {
        subheader: 'pulse',
        items: [
          {
            title: 'overview',
            path: paths.dashboard.dashboard,
            icon: ICONS.dashboard,
            permissions: RequiredPerm?.Dashboard,
            hasChildren: true
          },
          {
            title: 'Economics',
            path: paths.dashboard.finance,
            icon: ICONS.ecommerce,
            permissions: RequiredPerm?.Finance
          }
        ]
      },
      // CAMPAIGNS
      // ----------------------------------------------------------------------
      {
        subheader: 'campaigns',
        items: [
          {
            title: 'summary',
            path: paths.campaigns.root,
            icon: ICONS.external,
            permissions: RequiredPerm?.Marketing
          },
          {
            title: 'ads',
            path: paths.campaigns.ads,
            icon: ICONS.ads,
            permissions: RequiredPerm?.Ads,
            hasChildren: true
          },
          {
            title: 'discount',
            path: paths.campaigns.discount,
            icon: ICONS.invoice,
            permissions: RequiredPerm?.Discount,
            hasChildren: true
          },
          {
            title: 'calendar',
            path: paths.campaigns.calendar,
            icon: ICONS.calendar,
            permissions: RequiredPerm?.Calendar
          }
        ]
      },
      // GROW SOCIAL
      // ----------------------------------------------------------------------
      {
        subheader: 'GrowSocial 💎',
        items: [
          {
            title: 'GrowSocial',
            path: paths.growSocial.root,
            icon: ICONS.academy,
            permissions: RequiredPerm?.GrowSocial
          }
        ]
      },
      // GROW INTEL
      // ----------------------------------------------------------------------
      {
        subheader: 'growIntel 💎',
        info: <Label color='info'>NEW</Label>,
        items: [
          {
            title: 'Analyze My Menu',
            path: paths.growIntel.menu_analysis,
            icon: ICONS.menuAnalysis,
            permissions: RequiredPerm?.GrowIntel
          },
          {
            title: 'Menu Suggestion',
            path: paths.growIntel.menu_suggestion,
            icon: ICONS.menuSuggest,
            permissions: RequiredPerm?.GrowIntel
          },
          {
            title: 'Competitors',
            path: paths.growIntel.competitors,
            icon: ICONS.competitors,
            permissions: RequiredPerm?.GrowIntel
          },
          {
            title: 'Popular Brands',
            path: paths.growIntel.popular_brands,
            icon: ICONS.popularBrands,
            permissions: RequiredPerm?.GrowIntel
          },
          {
            title: 'Popular Items',
            path: paths.growIntel.popular_items,
            icon: ICONS.popularItems,
            permissions: RequiredPerm?.GrowIntel
          },
          {
            title: 'Market Change',
            path: paths.growIntel.market_changes,

            icon: ICONS.marketChange,
            permissions: RequiredPerm?.GrowIntel
          },
          {
            title: 'Item Search',
            path: paths.growIntel.item_search,
            icon: ICONS.itemSearch,
            permissions: RequiredPerm?.GrowIntel
          }
        ]
      },
      // OPERATIONS
      // ----------------------------------------------------------------------
      {
        subheader: 'Operations',
        items: [
          {
            title: 'Performance',
            path: paths.operations.root,
            icon: ICONS.operations,
            permissions: RequiredPerm?.Operations_Overview
          },
          {
            title: 'Feedback',
            path: paths.operations.reviews_and_rating,
            icon: ICONS.label,
            permissions: RequiredPerm?.ReviewsAndRatings
          }
        ]
      },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: 'management',
        items: [
          {
            title: 'profile',
            path: paths.management.profile,
            icon: ICONS.user,
            permissions: RequiredPerm?.Profile
          },
          {
            title: 'budget',
            path: paths.management.budget,
            icon: ICONS.banking,
            permissions: RequiredPerm?.Budget
          }
        ]
      },
      {
        subheader: 'Resource',
        items: [
          {
            title: 'Growdash Academy',
            path:
              userCountry.toLowerCase() === 'romania'
                ? paths.explore.growthhub
                : paths.explore.growthhub_extended,
            icon: ICONS.academy
          }
        ]
      }
    ];

    if (userCountry.toLowerCase() === 'romania') {
      return navItems
        .map(section => ({
          ...section,
          items: section.items.filter(item =>
            ROMANIA_MODULES.includes(item.title.toLowerCase())
          )
        }))
        .filter(section => section.items.length > 0);
    }

    return navItems;
  }, [userCountry]);

  return data;
}
