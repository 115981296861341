import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// hocs
import AuthGuard from '@hocs/auth-guard';
import { CheckPermission } from '@hocs/check-permission';
// components
import { DashboardLayout } from '@atoms/layouts';
import { SplashScreen } from '@atoms/loading-screen';
// routes
import { paths } from '../paths';
import { RequiredPerm } from '../permissions';

// ----------------------------------------------------------------------

// OVERVIEW
const ProfilePage = lazy(() => import('@pages/management/ProfilePage'));
const CreditNotesPage = lazy(() => import('@pages/management/CreditNotesPage'));
const BudgetPage = lazy(() => import('@pages/management/BudgetPage'));

// ----------------------------------------------------------------------

export const managementRoutes = [
  {
    path: paths?.management?.root,
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<SplashScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        element: (
          <CheckPermission permissionRequired={RequiredPerm?.Management}>
            <ProfilePage />
          </CheckPermission>
        ),
        index: true
      },
      {
        path: paths?.management?.credit_notes,
        element: (
          <CheckPermission permissionRequired={RequiredPerm?.Management}>
            <CreditNotesPage />
          </CheckPermission>
        ),
        index: true
      },

      {
        path: paths?.management?.budget,
        element: (
          <CheckPermission permissionRequired={RequiredPerm?.Budget}>
            <BudgetPage />
          </CheckPermission>
        )
      },
      {
        path: paths?.management?.profile,
        element: (
          <CheckPermission permissionRequired={RequiredPerm?.Management}>
            <ProfilePage />
          </CheckPermission>
        )
      }
    ]
  }
];
