import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// components
import AuthModernCompactLayout from '@atoms/layouts/auth/modern-compact';
import { SplashScreen } from '@atoms/loading-screen';
// paths
import { paths } from '../paths';

// Pages
const LoginPage = lazy(() => import('@pages/auth/LoginPage'));
const ResetPasswordPage = lazy(() => import('@pages/auth/ResetPasswordPage'));

const authJwt = {
  path: '',
  element: (
    <Suspense fallback={<SplashScreen />}>
      <Outlet />
    </Suspense>
  ),
  children: [
    {
      path: paths?.auth?.jwt?.login,
      element: (
        <AuthModernCompactLayout>
          <LoginPage />
        </AuthModernCompactLayout>
      )
    },
    {
      path: paths?.auth?.jwt?.reset_password,
      element: (
        <AuthModernCompactLayout>
          <ResetPasswordPage />
        </AuthModernCompactLayout>
      )
    }
  ]
};

export const authRoutes = [
  {
    path: '',
    children: [authJwt]
  }
];
