import { useState, useEffect, useCallback } from 'react';
// hooks
import { useActiveLink, usePathname } from '@hooks/routes';
//
import { NavListProps, NavConfigProps } from '../types';
import NavItem from './nav-item';

// ----------------------------------------------------------------------

type NavListRootProps = {
  data: NavListProps;
  depth: number;
  hasChild: boolean;
  config: NavConfigProps;
  disabled?: boolean;
};

export default function NavList({
  data,
  depth,
  hasChild,
  config
}: NavListRootProps) {
  const pathname = usePathname();

  const active = useActiveLink(data.path, hasChild);

  const externalLink = data.path.includes('http');

  const [open, setOpen] = useState(active);

  useEffect(() => {
    if (!active) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleToggle = useCallback(() => {
    setOpen(prev => !prev);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <NavItem
      item={data}
      depth={depth}
      open={open}
      active={active}
      externalLink={externalLink}
      onClick={handleToggle}
      config={config}
    />
  );
}

// ----------------------------------------------------------------------
