const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/pulse',
  DASHBOARD_OLD: '/dashboard',
  CAMPAIGNS: '/campaigns',
  GROW_SOCIAL: '/grow-social',
  GROW_INTEL: '/grow-intel',
  OPERATIONS: '/operations',
  MANAGEMENT: '/management',
  GROWDASH_ACADEMY: 'https://mygrowdash.productfruits.help/en',
  EXPLORE: '/explore'
};

// ----------------------------------------------------------------------

export const paths = {
  // ERRORS
  error: {
    403: '/403',
    404: '/404',
    500: '/500'
  },
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/login`,
      register: `${ROOTS.AUTH}/register`,
      reset_password: `${ROOTS.AUTH}/reset-password`
    }
  },
  // CAMPAIGNS
  campaigns: {
    root: ROOTS.CAMPAIGNS,
    ads: `${ROOTS.CAMPAIGNS}/ads`,
    ads_detail: (id: string) => `${ROOTS.CAMPAIGNS}/ads/${id}`,
    area_performance: `${ROOTS.CAMPAIGNS}/ads/area-performance`,
    campaign_add: `${ROOTS.CAMPAIGNS}/ads/campaign/create`,
    campaign_edit: (id: string) => `${ROOTS.CAMPAIGNS}/ads/campaign/edit/${id}`,
    discount: `${ROOTS.CAMPAIGNS}/discount`,
    discount_detail: (id: string) => `${ROOTS.CAMPAIGNS}/discount/${id}`,
    rbdc_discount_detail: (id: string) =>
      `${ROOTS.CAMPAIGNS}/discount/rbdc/${id}`,
    discount_campaign_add: `${ROOTS.CAMPAIGNS}/discount/campaign/create`,
    market: `${ROOTS.CAMPAIGNS}/market`,
    calendar: `${ROOTS.CAMPAIGNS}/calendar`
  },
  // DASHBOARD
  // INFO! for old routes use DASHBOARD_OLD
  dashboard: {
    root: ROOTS.DASHBOARD,
    root_old: ROOTS.DASHBOARD_OLD,
    dashboard: `${ROOTS.DASHBOARD}/overview`,
    dashboard_old: `${ROOTS.DASHBOARD_OLD}/overview`,
    // grow_intel: `${ROOTS.DASHBOARD}/grow-intel?tab=item_search`,
    growth: `${ROOTS.DASHBOARD}/growth`,
    finance: `${ROOTS.DASHBOARD}/economics`,
    finance_old: `${ROOTS.DASHBOARD_OLD}/finance`,
    overview_details: `${ROOTS.DASHBOARD}/overview/details`
  },
  // MANAGEMENT
  management: {
    root: ROOTS.MANAGEMENT,
    profile: `${ROOTS.MANAGEMENT}/profile`,
    credit_notes: `${ROOTS.MANAGEMENT}/credit-notes`,
    budget: `${ROOTS.MANAGEMENT}/budget`
  },
  // GROW_SOCIAL
  growSocial: {
    root: ROOTS.GROW_SOCIAL
  },
  // GROW_INTEL
  growIntel: {
    root: ROOTS.GROW_INTEL,
    item_search: `${ROOTS.GROW_INTEL}/item_search`,
    menu_suggestion: `${ROOTS.GROW_INTEL}/menu_suggestion`,
    menu_analysis: `${ROOTS.GROW_INTEL}/menu_analysis`,
    competitors: `${ROOTS.GROW_INTEL}/competitors`,
    popular_brands: `${ROOTS.GROW_INTEL}/popular_brands`,
    popular_items: `${ROOTS.GROW_INTEL}/popular_items`,
    market_changes: `${ROOTS.GROW_INTEL}/market_changes`,
    grow_intel_restaurant: `${ROOTS.GROW_INTEL}/restaurant/:id`
  },
  // OPERATIONS
  operations: {
    root: ROOTS.OPERATIONS,
    reviews_and_rating: `${ROOTS.OPERATIONS}/reviews-ratings`
  },
  // EXPLORE
  explore: {
    growthhub: `${ROOTS?.EXPLORE}/growthhub`,
    growthhub_extended: ROOTS.GROWDASH_ACADEMY
  }
};
